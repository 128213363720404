<div class="section mcb-section">
  <div class="section_wrapper mcb-section-inner">
    <div class="wrap mcb-wrap one-third  valign-top clearfix" style="">
      <div class="mcb-wrap-inner">
        <div class="column mcb-column one column_divider ">
          <hr class="no_line" style="margin: 0 auto 10px;">
        </div>
      </div>
    </div>
    <div class="wrap mcb-wrap two-third  valign-top clearfix" style="">
      <div class="mcb-wrap-inner">
        <div class="column mcb-column one column_column ">
          <div class="column_attr clearfix" style="">
            <h1 style="text-align: center; margin: 0;">Servicii profesionale instalatii sanitare - termice</h1>
          </div>
        </div>
        <div class="column mcb-column one column_icon_box ">
          <div class="animate fadeIn" data-anim-type="fadeIn">
            <div class="icon_box icon_position_left no_border">
              <div class="icon_wrapper">
                <div class="icon">
                    <div class="icon__image truck"></div>
                </div>
              </div>
              <div class="desc_wrapper">
                <h4 class="title">CONSTATARE</h4>
                <div class="desc">Deplasarea instalatorului nostru pentru constatare este GRATUITA.<br>
                  Raspundem rapid solicitarilor venite din partea clientilor nostrii, oferindu-va suport tehnic ori de
                  cate ori veti avea nevoie de noi.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="column mcb-column one column_icon_box ">
          <div class="animate fadeIn" data-anim-type="fadeIn">
            <div class="icon_box icon_position_left no_border">
              <div class="icon_wrapper">
                <div class="icon">
                    <div class="icon__image waranty"></div>
                </div>
              </div>
              <div class="desc_wrapper">
                <h4 class="title">GARANTIE</h4>
                <div class="desc">Să oferă <strong>certificat de garanție</strong> pentru fiecare lucrare în parte.<br>
                  Folosim echipamente si materiale de ultima generatie pentru lucrari sigure certificate tehnic de
                  tehnicienii nostri.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
