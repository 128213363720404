<div class="section padding-bottom-extra">
  <div class="container flex-horizontal">
    <div class="col lg-12 md-12 no-margin-bottom-lg">
      <div class="sidebar-page-container">
        <h1 class="c19" id="h.bngtk3t6pvzg"><span class="c21">POLITICA DE CONFIDENȚIALITATE</span></h1>
        <p class="c7"><span class="c3">Acest website reprezintă proprietatea LUK-INSTALL PERFECT 24 SRL, denumit in
            continuare
            “Furnizor”</span></p>
        <p class="c22 c16"><span class="c2">Scopul acestei politici de confidențialitate este să vă explice ce date
            culegem de
            la vizitatorii website-ului nostru, de ce le culegem și ce facem cu ele.</span></p>
        <p class="c7"><span class="c3">Furnizorul </span><span class="c2">dorește să protejeze confidențialitatea
            utilizatorilor care vizitează website-ului nostru și facem toate demersurile necesare în acest sens. </span>
        </p>
        <p class="c22 c16"><span class="c2">Această politică de confidențialitate se aplică exclusiv datelor colectate
            prin
            intermediul acestui site.</span></p>
        <hr>
        <p class="c16 c9 c22"><span class="c2"></span></p>
        <p class="c4"><span class="c5 c3">Cine va prelucra datele dvs. cu caracter personal?</span></p>
        <p class="c4"><span>Prelucrarea datelor dvs. cu caracter personal se va face de către societatea
            &nbsp;</span><span class="c3">LUK-INSTALL PERFECT 24 SRL</span><span class="c2">, cu sediul in sat Clinceni
            Com. Clinceni, Str.
            Ciocarliei, Lotul 1, Tarlaua 33/1, Parcelele Nr. 195, Nr. 195/1/32 Si Nr. 195/1/33 Identificat Cadastral Sub
            Nr.
            60606-C1-U1, Intabulat In C.F. Nr. 60606 A Clinceni, înregistrată la Registrul Comerțului sub J23/206/2018 |
            având
            cod fiscal RO36951457, persoană de contact Cionca Vasile, care depune toate eforturile în vederea protejării
            dreptului la protecția datelor cu caracter personal al utilizatorilor acestui site.</span></p>
        <hr>
        <p class="c4 c9"><span class="c2"></span></p>
        <p class="c4"><span class="c5 c3">Categoriile de date cu caracter personal prelucrate</span></p>
        <p class="c14 c16 c20"><span class="c3">LUK-INSTALL PERFECT 24 SRL </span><span class="c2">prelucrează, după
            caz,
            următoarele categorii de date cu caracter personal:</span></p>
        <ul class="c11 lst-kix_xicuh0ej1qc3-0 start">
          <li class="c8"><span class="c2">nume, prenume, adresa de livrare, telefonul, adresa de e-mail.</span></li>
          <li class="c8"><span class="c2">opinii şi viziuni pe care ni le transmiteţi sau pe care le postaţi despre noi
              pe
              reţelele de socializare (social media) sau alte canale publice,</span></li>
          <li class="c8"><span class="c2">eventuale alte date cu caracter personal pe care decideți să ni le furnizați
              prin
              intermediul site-ului, prin comunicările și solicitările pe care ni le transmiteți.</span></li>
        </ul>
        <hr>
        <p class="c4 c9"><span class="c2"></span></p>
        <p class="c22 c16"><span class="c3">Colectarea datelor se face prin: </span></p>
        <ul class="c11 lst-kix_9ggcf5omgj4c-0 start">
          <li class="c15"><span class="c2">Formularul de contact </span></li>
          <li class="c15"><span class="c2">Telefonic</span></li>
          <li class="c15"><span class="c2">Modulul de comunicare WhatsApp</span></li>
        </ul>
        <hr>
        <p class="c22 c16 c9 c26"><span class="c2"></span></p>
        <p class="c6 c14"><span class="c5 c3">Datele cu caracter personal care sunt colectate prin intermediul acestui
            website, sunt folosite cu următoatele scopuri:</span></p>
        <ul class="c11 lst-kix_by98rjtazq03-0 start">
          <li class="c18 c13"><span class="c3">Comunicarea</span><span class="c2">&nbsp;directă cu clienții și
              potențialii
              clienți</span></li>
          <li class="c18 c13"><span class="c3">Facturarea </span><span class="c2">produselor comandate </span></li>
          <li class="c13 c18"><span class="c3">Livrarea</span><span class="c2">&nbsp;produselor comandate</span></li>
          <li class="c18 c13"><span class="c3">Informarea</span><span class="c2">&nbsp;utilizatorilor cu privire la
              oferte/
              promotii, statusul comenzilor, evaluarea comenzilor efectuate</span></li>
        </ul>
        <hr>
        <p class="c0"><span class="c2"></span></p>
        <p class="c6"><span class="c5 c3">Protejarea informațiilor personale usi</span></p>
        <p class="c13"><span class="c2">Acest site și toată informația pe care o dezvălui pe acest site este colectată,
            depozitată și procesată în România. Permitem accesul la datele personale exclusiv angajaților și
            furnizorilor de
            servicii care au nevoie de ele pentru a asigura buna funcționare a acestui site și pentru livrarea
            produselor
            noastre. </span></p>
        <p class="c13"><span class="c2">Am implementat sisteme de securitate fizică, administrativă și tehnică pentru a
            proteja datele personale de accesul neautorizat. Cu toate acestea, pe cât de eficiente sunt sistemele
            noastre de
            securitate, niciun sistem nu este impenetrabil. Nu putem garanta impenetrabilitatea serviciilor noastre de
            securitate și nici faptul că informația transmisă către noi nu va fi interceptată în momentul în care este
            divulgată.</span></p>
        <hr>
        <p class="c13 c9"><span class="c2"></span></p>
        <p class="c4"><span class="c5 c3">Durata prelucrării datelor cu caracter personal</span></p>
        <p class="c4"><span class="c2">Datele vor fi prelucrate pe întreaga perioadă de timp necesară realizării
            scopului
            pentru care acestea au fost colectate, cu asigurarea unor măsuri corespunzătoare de securitate.</span></p>
        <p class="c4"><span class="c2">De exemplu, datele dvs. care sunt colectate în scopuri legate de încheierea unui
            contract vor fi păstrate pe durata executării contractului cât și ulterior, pe perioada de timp impusă de
            legislația în vigoare pentru păstrarea documentelor. </span></p>
        <p class="c4"><span class="c3">LUK-INSTALL PERFECT 24 SRL</span><span class="c2">&nbsp;va revizui periodic
            datele
            colectate, analizând în ce măsură păstrarea lor este necesară scopurilor menționate iar dacă nu mai sunt
            necesare
            datele vor fi șterse.</span></p>
        <hr>
        <p class="c4 c9"><span class="c2"></span></p>
        <p class="c13"><span class="c3 c16">Temeiul legal al prelucrării datelor cu caracter personal</span></p>
        <p class="c13"><span class="c3">LUK-INSTALL PERFECT 24 SRL </span><span>își asumă obligația de a administra
            datele
            personale furnizate de utilizatorii siteului nostru în condiții de siguranță și exclusiv pentru scopurile
            specificate, conform &nbsp;prevederilor</span><span class="c3">&nbsp;Legii 2016/679/UE</span><span
            class="c2">&nbsp;pentru protecția prelucrării datelor cu caracter personal și libera circulație a acestor
            date.</span></p>
        <p class="c10"><span class="c3 c5">Conform regulamentului 2016/679/UE utilizatorii site-ului www.lukinstall.ro
            au
            următoarele drepturi:</span></p>
        <ul class="c11 lst-kix_xv8guluo1rus-0 start">
          <li class="c12"><span class="c3">Dreptul de acces</span><span class="c2">&nbsp;înseamnă că ai dreptul de a
              obține o
              confirmare din partea noastră că prelucrăm sau nu datele cu caracter personal care te privesc și, în caz
              afirmativ, acces la datele respective și la informații privind modalitatea în care sunt prelucrate
              datele.</span></li>
          <li class="c12"><span class="c3">Dreptul la portabilitatea datelor</span><span class="c2">&nbsp;se referă la
              dreptul
              de a primi datele personale într-un format structurat, utilizat în mod curent și care poate fi citit
              automat și
              la dreptul ca aceste date să fie transmise direct altui operator, dacă acest lucru este fezabil din punct
              de
              vedere tehnic.</span></li>
          <li class="c12"><span class="c3">Dreptul la opoziție</span><span class="c2">&nbsp;vizează dreptul de a te
              opune
              prelucrării datelor personale atunci când prelucrarea este necesară pentru îndeplinirea unei sarcini care
              servește unui interes public sau când are în vedere un interes legitim al operatorului. Atunci când
              prelucrarea
              datelor cu caracter personal are drept scop marketingul direct, ai dreptul de a te opune prelucrării în
              orice
              moment.</span></li>
          <li class="c12"><span class="c3">Dreptul la rectificare</span><span class="c2">&nbsp;se referă la corectarea,
              fără
              întârzieri nejustificate, a datelor cu caracter personal inexacte. Rectificarea va fi comunicată fiecărui
              destinatar la care au fost transmise datele, cu excepția cazului în care acest lucru se dovedește
              imposibil sau
              presupune eforturi disproporționate.</span></li>
          <li class="c12"><span class="c3">Dreptul la ștergerea datelor (“dreptul de a fi uitat”)</span><span
              class="c2">&nbsp;înseamnă că ai dreptul de a solicita ștergerea datelor cu caracter personal, fără
              întârzieri
              nejustificate, în cazul în care se aplică unul dintre următoarele motive: acestea nu mai sunt necesare
              pentru
              îndeplinirea scopurilor pentru care au fost colectate sau prelucrate; îți retragi consimțământul și nu
              există
              niciun alt temei juridic pentru prelucrare; te opui prelucrării și nu există motive legitime care să
              prevaleze;
              datele cu caracter personal au fost prelucrate ilegal; datele cu caracter personal trebuie șterse pentru
              respectarea unei obligații legale; datele cu caracter personal au fost colectate în legătură cu oferirea
              de
              servicii ale societății informaționale.</span></li>
          <li class="c12"><span class="c3">Dreptul la restricționarea prelucrării</span><span>&nbsp;poate fi exercitat
              în
              cazul în care persoana contestă exactitatea datelor, pe o perioadă care ne permite verificarea
              corectitudinii
              datelor; prelucrarea este ilegală, iar persoana se opune ștergerii datelor cu caracter personal,
              solicitând în
              schimb restricționarea; în cazul în care </span><span class="c3">Hero Print</span><span class="c2">&nbsp;
              nu mai
              are nevoie de datele cu caracter personal în scopul prelucrării, dar persoana i le solicită pentru
              constatarea,
              exercitarea sau apărarea unui drept în instanță; în cazul în care persoana s-a opus prelucrării pentru
              intervalul de timp în care se verifică dacă drepturile legitime ale operatorului prevalează asupra celor
              ale
              persoanei respective.</span></li>
          <li class="c12"><span class="c2">Toate aceste drepturi pot sa fi exercitate printr-o cerere scrisă, semnată și
              datată, transmisă la sediul nostru sau la adresa de email de mai jos.</span></li>
        </ul>
        <hr>
        <p class="c16 c9 c17"><span class="c2"></span></p>
        <p class="c10"><span class="c5 c3">Întrebări sau nelămuriri?</span></p>
        <p class="c10"><span>Dacă ai orice întrebări, nelămuriri sau reclamații legate de prezenta politică de
            confidențialitate sau de condițiile de confidențialitate în general, te rugăm să le direcționezi către
          </span><span class="c3">LUK-INSTALL PERFECT 24 SRL</span><span>&nbsp;la adresa de email </span><span
            class="c25"><a class="c24"
              href="mailto:lukinstall24perfect@yahoo.com">lukinstall24perfect@yahoo.com</a></span><span
            class="c2">&nbsp; sau telefonic la 0743 697 295.</span></p>
        <p class="c1"><span class="c2"></span></p>
        <p class="c1"><span class="c2"></span></p>
      </div>
    </div>
  </div>
</div>