<!-- About Section -->
<div class="about-section">
  <div class="auto-container">
    <div class="row clearfix">

      <!-- Content Column -->
      <div class="content-column col-lg-12 col-md-12 col-sm-12">
        <div class="inner-column">
          <div class="gallery-wrapper">
            <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End About Section -->
