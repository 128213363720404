	<!-- Page Title -->
	<section class="page-title" style="background-image: url('/assets/images/montaj-centrale-termice (1).jpg')">
	  <div class="auto-container">
	    <h1>Montaj Centrale Termice</h1>
	    <ul class="page-breadcrumb" style="display: none">
	      <li><a href="/">Acasa</a></li>
	      <li>Montaj Centrale Termice</li>
	    </ul>
	  </div>
	</section>
	<!-- End Page Title -->

	<!-- About Section -->
	<div class="about-section">
	  <div class="auto-container">
	    <div class="row clearfix">

	      <!-- Content Column -->
	      <div class="content-column col-lg-6 col-md-12 col-sm-12">
	        <div class="inner-column">
	          <div class="title"><span class="icon"><img src="/assets/images/icons/cog.svg" alt="" /></span> Montaj
	            centrale termice Bucuresti si Ilfov</div>
	          <h2>Montaj profesional pentru orice tip de centrala termica</h2>
	          <div class="text">
	            <p>Montajul standard al centralelor termice presupune racordarea tevilor de cupru, Pexal sau PPR existente
	              in sistem la centrala termica. Aceste tevi trebuie sa fie deja prezente in incaperea in care este montata
	              centrala sau se poate reface traseul termic al locuintei astfel incat sa asiguram funtionarea optima a
	              centralei termice.</p>

	            <p><strong>Montajul centralei termice se face de un instalator autorizat cu o vasta experienta in domeniul
	                instalatiilor termice.</strong></p>
	            <ul>
	              <li>- Realizam montaj pentru orice tip de centrala, cu certificat de garantie pentru fiecare centrala
	                montata.</li>
	              <li>- Realizam si proiectam sisteme de incalzire pentru locuinte sau spatii comerciale asigurand un
	                serviciu prompt si garantat tehnic de specialistii nostri. </li>
	              <li>- Asiguram verificarea optima a intregii instalatii termice dupa punerea in functiune a sistemului de
	                incalzire.</li>

	            </ul>
	          </div>
	          <div class="question">Ai o intrebare? Suna un instalator: <a href="tel:+40743697295">0743-697-295</a></div>
	        </div>
	      </div>

	      <!-- Images Column -->
	      <div class="image-column col-lg-6 col-md-12 col-sm-12">
	        <div class="inner-column">
	          <div class="award-image">
	            <img src="assets/images/garantie-instalator.png" alt="" />
	          </div>
	          <div class="image">
	            <img src="/assets/images/montaj-centrala-termica.jpg" alt="" />
	          </div>
	        </div>
	      </div>
	    </div>
	  </div>
	</div>
	<!-- End About Section -->

	<section class="featured-section-two">
	  <div class="image-layer" style="background-image:url(assets/images/background/pattern-2.png)"></div>
	  <div class="auto-container">
	    <div class="inner-container">

	      <div class="clearfix">

	        <!-- Featured Section -->
	        <section class="featured-section">
	          <div class="auto-container">
	            <div class="inner-container">

	              <div class="clearfix">

	                <!-- Featured Block -->
	                <div class="featured-block col-lg-4 col-md-6 col-sm-12">
	                  <div class="inner-box">
	                    <div class="content">
	                      <div class="icon-box">
	                        <img src="/assets/images/icons/boiler.svg" alt="">
	                      </div>
	                      <h5><a>Te ajutam sa alegi centrala</a></h5>
	                    </div>
	                  </div>
	                </div>

	                <!-- Featured Block -->
	                <div class="featured-block col-lg-4 col-md-6 col-sm-12">
	                  <div class="inner-box">
	                    <div class="content">
	                      <div class="icon-box">
	                        <img src="/assets/images/icons/legal.svg" alt="">
	                      </div>
	                      <h5><a>Suport in obtinerea autorizatiilor</a></h5>
	                    </div>
	                  </div>
	                </div>

	                <!-- Featured Block -->
	                <div class="featured-block col-lg-4 col-md-6 col-sm-12">
	                  <div class="inner-box">
	                    <div class="content">
	                      <div class="icon-box">
	                        <img src="/assets/images/icons/plumber.svg" alt="">
	                      </div>
	                      <h5><a>Instalatori autorizati ANRE</a></h5>
	                    </div>
	                  </div>
	                </div>

	              </div>

	            </div>
	          </div>
	        </section>
	        <!-- End Featured Section -->

	      </div>

	    </div>
	  </div>
	</section>
	<!-- Counter Section -->
	<section class="counter-section">
	  <div class="auto-container">

	    <!-- Fact Counter -->
	    <div class="fact-counter">
	      <div class="row clearfix">

	        <!-- Column -->
	        <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
	          <div class="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
	            <div class="content">
	              <div class="count-outer count-box">
	                <span class="count-text" data-speed="3000" data-stop="350">0</span>+
	              </div>
	              <h4 class="counter-title">Lucrari Instalatii Termice</h4>
	            </div>
	          </div>
	        </div>

	        <!-- Column -->
	        <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
	          <div class="inner wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
	            <div class="content">
	              <div class="count-outer count-box alternate">
	                <span class="count-text" data-speed="5000" data-stop="460">0</span>+
	              </div>
	              <h4 class="counter-title">Lucrari Instalatii Sanitare</h4>
	            </div>
	          </div>
	        </div>

	        <!-- Column -->
	        <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
	          <div class="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
	            <div class="content">
	              <div class="count-outer count-box">
	                <span class="count-text" data-speed="2000" data-stop="15">0</span>+
	              </div>
	              <h4 class="counter-title">Ani de Experienta</h4>
	            </div>
	          </div>
	        </div>

	        <!-- Column -->
	        <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
	          <div class="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
	            <div class="content">
	              <div class="count-outer count-box">
	                <span class="count-text" data-speed="3500" data-stop="390">0</span>+
	              </div>
	              <h4 class="counter-title">Urgente Rezolvate</h4>
	            </div>
	          </div>
	        </div>

	      </div>
	    </div>

	  </div>
	</section>
	<!-- End Counter Section -->

	<section class="featured-section-three">
	  <div class="auto-container">
	    <div class="inner-container">
	      <h3>De ce sa alegi Luk Install?</h3>
	      <div class="row clearfix">

	        <!-- Left Column -->
	        <div class="left-column col-lg-4 col-md-6 col-sm-12">
	          <div class="inner-column">

	            <!-- Featured Block Three -->
	            <div class="featured-block-three">
	              <div class="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms"
	                style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInLeft;">
	                <div class="icon-box">
	                  <span class="icon"><img src="assets/images/icons/speed.svg" alt=""></span>
	                </div>
	                <h5><a>Te ajutam sa alegi centrala termica</a></h5>
	                <div class="text">Te ajutam sa obtii toate autorizatiile de functionare</div>
	              </div>
	            </div>

	            <!-- Featured Block Three -->
	            <div class="featured-block-three color-two">
	              <div class="inner-box wow fadeInLeft animated" data-wow-delay="150ms" data-wow-duration="1500ms"
	                style="visibility: visible; animation-duration: 1500ms; animation-delay: 150ms; animation-name: fadeInLeft;">
	                <div class="icon-box">
	                  <span class="icon"><img src="assets/images/icons/guranatee.svg" alt=""></span>
	                </div>
	                <h5><a>Avem instalatori autorizati ANRE</a></h5>
	                <div class="text">Realizam instalarea centralei termice</div>
	              </div>
	            </div>

	          </div>
	        </div>

	        <!-- Icon Column -->
	        <div class="icon-column col-lg-4 col-md-12 col-sm-12">
	          <div class="inner-column">
	            <div class="circle-one"></div>
	            <div class="circle-two"></div>
	            <div class="circle-three"></div>
	            <div class="icon">
	              <img src="assets/images/instalator-montare-centrala-termica.png" width="60%" style="margin-top: -60px;"
	                alt="">
	            </div>
	          </div>
	        </div>

	        <!-- Right Column -->
	        <div class="right-column col-lg-4 col-md-6 col-sm-12">
	          <div class="inner-column">

	            <!-- Featured Block Three -->
	            <div class="featured-block-three color-two style-two">
	              <div class="inner-box wow fadeInRight animated" data-wow-delay="0ms" data-wow-duration="1500ms"
	                style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInRight;">
	                <div class="icon-box">
	                  <span class="icon"><img src="assets/images/icons/experts.svg" alt=""></span>
	                </div>
	                <h5><a>Oferim garantia lucrarii</a></h5>
	                <div class="text">Realizam verificarea instalatii pentru o functionare optima</div>
	              </div>
	            </div>

	            <!-- Featured Block Three -->
	            <div class="featured-block-three style-two">
	              <div class="inner-box wow fadeInRight animated" data-wow-delay="150ms" data-wow-duration="1500ms"
	                style="visibility: visible; animation-duration: 1500ms; animation-delay: 150ms; animation-name: fadeInRight;">
	                <div class="icon-box">
	                  <span class="icon"><img src="assets/images/icons/save.svg" alt=""></span>
	                </div>
	                <h5><a>Iti oferim cele mai bune sfaturi pentru centrala ta</a></h5>
	                <div class="text">Mentenanta post-lucrare</div>
	              </div>
	            </div>

	          </div>
	        </div>

	      </div>
	    </div>
	  </div>
	</section>

	<section class="call-to-action-section-two"
	  style="background-image: url(/assets/images/instalator-bucuresti-ilfov.jpg)">
	  <div class="auto-container">
	    <!-- Content Boxed -->
	    <div class="content-boxed">
	      <div class="inner-boxed">
	        <div class="title">CU NOI, AI DOAR DE CASTIGAT!</div>
	        <h1>Suntem partener ROMSTAL</h1>
	        <div class="text">Beneficiati pana la 25% reducere pentru toate materialele Romstal</div>
	        <div class="btns-box clearfix">
	          <a routerLink="/contact" class="theme-btn btn-style-one"><span class="txt">
	              Cere oferta</span></a>
	          <a routerLink="/contact" class="talk">Suna un instalator <span>0743 697 295</span></a>
	        </div>
	      </div>
	    </div>
	  </div>
	</section>





	<section class="premium-section style-two">
	  <div class="auto-container">
	    <h2>Instalator Bucuresti si Ilfov</h2>
	    <div class="row clearfix">

	      <!-- Security Block -->
	      <div class="security-block col-lg-4 col-md-6 col-sm-12">
	        <div class="inner-box">
	          <div class="image">
	            <a><img src="assets/images/resources/Montaj-Obiecte-Sanitare.jpg" alt=""></a>
	          </div>
	          <div class="lower-content">
	            <div class="hover-bg-color"></div>
	            <div class="upper-box">

	              <h5><a>Montaj Obiecte Sanitare</a></h5>
	            </div>
	            <div class="text">
	              <ul>
	                <li><span>></span> Chiuvete</li>
	                <li><span>></span> Lavuaruri</li>
	                <li><span>></span> Cazi de baie</li>
	                <li><span>></span> Cabine de dus</li>
	                <li><span>></span> Vase de WC</li>
	                <li><span>></span> Mașini de spălat vase</li>
	                <li><span>></span> Masini de spalat rufe</li>
	                <li><span>></span> Mașini de uscat rufe</li>
	                <li><span>></span> Boilere </li>
	                <li><span>></span> Centrale termice</li>
	              </ul>



	            </div>
	          </div>
	        </div>
	      </div>

	      <!-- Security Block -->
	      <div class="security-block col-lg-4 col-md-6 col-sm-12">
	        <div class="inner-box">
	          <div class="image">
	            <a><img src="assets/images/resources/Montaj-Profesional.jpg" alt=""></a>
	          </div>
	          <div class="lower-content">
	            <div class="hover-bg-color"></div>
	            <div class="upper-box">

	              <h5><a>Montaj Profesional</a></h5>
	            </div>
	            <div class="text">
	              <ul>
	                <li><span>></span> Baterii cazi</li>
	                <li><span>></span> Chiuvete, bideuri</li>
	                <li><span>></span> Centrale termice</li>
	                <li><span>></span> Calorifere</li>
	                <li><span>></span> Robineti de trecere</li>
	                <li><span>></span> Robineti de calorifere</li>
	                <li><span>></span> Hote</li>
	                <li><span>></span> Cuptoare</li>
	                <li><span>></span> Apometre</li>
	                <li><span>></span> Filtre de apa</li>
	              </ul>
	            </div>
	          </div>
	        </div>
	      </div>

	      <!-- Security Block -->
	      <div class="security-block col-lg-4 col-md-6 col-sm-12">
	        <div class="inner-box">
	          <div class="image">
	            <a><img src="assets/images/resources/Servicii-Complete.jpg" alt=""></a>
	          </div>
	          <div class="lower-content">
	            <div class="hover-bg-color"></div>
	            <div class="upper-box">
	              <h5><a>Servicii Complete</a></h5>
	            </div>
	            <div class="text">
	              <ul>
	                <li><span>></span> Desfundare profesionala</li>
	                <li><span>></span> Desfundare canalizare</li>
	                <li><span>></span> Instalații electrice (Autorizat ANR)</li>
	                <li><span>></span> Desfundari coloane comune</li>
	                <li><span>></span> Înlocuire coloane orizontale subsoluri</li>
	                <li><span>></span> Desfundari coloane comune</li>
	                <li><span>></span> Desfundare canalizare</li>
	                <li><span>></span> Inlocuire tevi sparte</li>
	                <li><span>></span> Inlocuire tablouri electrice</li>
	              </ul>
	            </div>
	          </div>
	        </div>
	      </div>

	    </div>

	  </div>
	</section>
	<div class="featured-blocks">
	  <div class="auto-container">
	    <div class="row">

	      <div class="featured-block col-lg-8 col-md-7 col-sm-12">
            <h4>Serviciul de montare centrale termice poate deveni URGENT in cazurile in care doriti sa montati o centrala si numai este loc de amanare.</h4>
            <img src="/assets/images/boiler_installation.jpg" alt="">
	      </div>
	      <div class="featured-block col-lg-4 col-md-5 col-sm-12">
	        <div class="sidebar-widget timing-widget">
	          <div class="widget-inner">
                  <div class="help-widget">
                    <h3 _ngcontent-hsb-c71="">Cauti un instalator?</h3>
                    <div class="title">Suna sau trimite email</div>
                    <a href="tel:+40743697295" class="phone">0743-697-295</a>
                    <a href="tel:+40732831826" class="phone">0732-831-826</a>
                    <a href="tel:+40771506190" class="phone">0771-506-190</a>
                  
                    <a href="mailto:office@lukinstall.ro" class="email">office@lukinstall.ro</a>
                  </div>
                
	            <h3>Program</h3>
	            <ul>
	              <li>Luni - Vineri <span>07:00 – 22:00</span></li>
	              <li>Sambata - Duminica <span>08:00 – 22:00</span></li>
	            </ul>
	          </div>
	        </div>
	      </div>
	    </div>
	  </div>
	</div>
	<section class="call-to-action-section">
	  <div class="auto-container">
	    <div class="row clearfix">
	      <!-- Title Column -->
	      <div class="title-column col-lg-8 col-md-12 col-sm-12">
	        <div class="inner-column">
	          <div class="phone flaticon-call-3"></div>
	          <div class="text"><span>Suna un instalator Bucuresti si Ilfov pentru servicii rapide si de durata.
	              <br></span> Telefon: <span>0743-697-295</span></div>
	        </div>
	      </div>
	      <!-- Button Column -->
	      <div class="button-column col-lg-4 col-md-12 col-sm-12">
	        <div class="inner-column">
	          <a href="/contact" class="theme-btn btn-style-two"><span class="txt"> CERE
	              OFERTA</span></a>
	        </div>
	      </div>
	    </div>
	  </div>
	</section>
