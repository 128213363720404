<footer class="main-footer">
  <div class="auto-container">
    <!-- Upper Box -->
    <div class="upper-box">
      <div class="row clearfix">
        <!-- Column -->
        <div class="text-column col-lg-4 col-md-12 col-sm-12">
          <div class="text">Ne deplasam oriunde in Bucuresti si Ilfov</div>
        </div>
        <!-- Column -->
        <div class="info-column col-lg-8 col-md-12 col-sm-12">
          <div class="row clearfix">
            <!-- Contact Info Box -->
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <ul class="contact-info-box">
                <li>
                  <span class="icon flaticon-call-3"></span>
                  Intrebari?
                  <strong>0743 697 295</strong>
                </li>
              </ul>
            </div>

            <!-- Contact Info Box -->
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <ul class="contact-info-box">
                <li>
                  <span class="icon flaticon-email-5"></span>
                  Cere oferta
                  <strong>office@lukinstall.ro</strong>
                </li>
              </ul>
            </div>

            <!-- Contact Info Box -->
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <ul class="contact-info-box">
                <li>
                  <span class="icon flaticon-in-time"></span>
                  Program
                  <strong>Non stop, 24/7</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Widgets Section -->
    <div class="widgets-section">
      <div class="row clearfix">
        <!-- Big Column -->
        <div class="big-column col-lg-6 col-md-12 col-sm-12">
          <div class="row clearfix">
            <!--Footer Column-->
            <div class="footer-column col-lg-6 col-md-6 col-sm-12">
              <div class="footer-widget logo-widget">
                <div class="logo">
                  <a href="/"><img src="/assets/images/logo.png" alt="" /></a>
                </div>
                <div class="text">
                  Firma noastra va pune la dispozitie non stop instalatori
                  autorizati pentru a efectua o gama variata de lucrari la
                  nivelul instalatiilor sanitare, termice, instalatiilor de gaze
                  sau electrice. Un serviciu profesional oferit de firma Luk
                  Install Bucuresti.
                </div>
                <div class="social-links">
                  <a
                    href="https://www.facebook.com/lukinstall.ro/"
                    class="fa fa-facebook"
                  ></a>
                </div>
              </div>
            </div>

            <!-- Footer Column -->
            <div class="footer-column col-lg-6 col-md-6 col-sm-12">
              <div class="footer-widget links-widget">
                <h5>ZONE DE DEPLASARE</h5>
                <ul class="list-link">
                  <li><a>Instalator Sector 1</a></li>
                  <li><a>Instalator Sector 2</a></li>
                  <li><a>Instalator Sector 3</a></li>
                  <li><a>Instalator Sector 4</a></li>
                  <li><a>Instalator Sector 5</a></li>
                  <li><a>Instalator Sector 6</a></li>
                  <li><a>Instalator Ilfov</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Big Column -->
        <div class="big-column col-lg-6 col-md-12 col-sm-12">
          <div class="row clearfix">
            <!-- Footer Column -->
            <div class="footer-column col-lg-6 col-md-6 col-sm-12">
              <div class="footer-widget links-widget">
                <h5>SERVICII INSTALATOR BUCURESTI</h5>
                <ul class="list-link">
                  <li>
                    <a routerLink="/instalator-non-stop">Instalator 24/7</a>
                  </li>
                  <li>
                    <a routerLink="/instalator-sanitar">Instalator Sanitar</a>
                  </li>
                  <li>
                    <a routerLink="/instalatii-termice">Instalatii Termice</a>
                  </li>
                  <li>
                    <a routerLink="/montaj-centrale-termice"
                      >Montaj Centrale Termice</a
                    >
                  </li>
                  <li>
                    <a routerLink="/desfundare-canalizare"
                      >Desfundare Canalizare</a
                    >
                  </li>
                </ul>
              </div>
            </div>

            <!-- Footer Column -->
            <div class="footer-column col-lg-6 col-md-6 col-sm-12">
              <div class="footer-widget location-widget">
                <h5>UTILE</h5>
                <ul class="list-link">
                  <li><a routerLink="/blog">Blog</a></li>
                  <li>
                    <a routerLink="/termeni-si-conditii">Termeni si Conditii</a>
                  </li>
                  <li>
                    <a routerLink="/politica-cookies">Setari GDPR & Cookies</a>
                  </li>
                  <li>
                    <a routerLink="/confidentialitate">Confidetialitate</a>
                  </li>
                  <li><a routerLink="/contct">Contact</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="copyright">
          Facut cu ☕ de Terra Agency.<br />
          <div class="anpc">
            <a
              href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
              target="_blank"
            >
              <img src="/assets/images/anpc1.jpeg" alt="" />
            </a>
            <a href="https://anpc.ro/ce-este-sal/" target="_blank">
              <img src="/assets/images/anpc2sal.jpeg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
