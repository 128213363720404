import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instalator-sector-two',
  templateUrl: './instalator-sector-two.component.html',
  styleUrls: ['./instalator-sector-two.component.scss']
})
export class InstalatorSectorTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
