<div class="section position-relative">
    <div class="c-shadowtext">Servicii</div>
    <div class="container position-relative">
      <div class="col block-centered lg-8 md-12 text-align-center position-relative">
        <h2>Servicii de Contabilitate in Bucuresti si Ilfov</h2>
        <div class="text-medium low-text-contrast">Suntem o firma de contabilitate formata din specialisti care inteleg necesitatile tale de business. Colaboram cu antreprenori din cele mai diverse domenii de activitate.
          Iti putem oferi urmatoarele servicii:
          </div>
      </div>
    </div>
   

    <div class="container">
      <div class="col lg-12 text-align-center">
        <div class="w-layout-grid c-grid2x4">
          <a href="#" id="w-node-59df5b131331-864b8345"
            class="link-block-2 w-inline-block">
            <div class="c-gridgallery1__content-2">
              <div class="on-dark text-medium weight-is-medium size-h2"> Contabilitate SRL</div>
              <a routerLink="/servicii-contabilitate-srl" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="button-primary is-small w-button">Detalii</a>
            </div>
            <div class="c-gridgallery1__image-2 img3"></div>
          </a>
          <a href="#" id="w-node-59df5b13133e-864b8345"
            class="link-block-2 w-inline-block">
            <div class="c-gridgallery1__content-2">
              <div class="on-dark text-medium weight-is-medium size-h2">Salarizare si Resurse Umane</div>
              <a routerLink="/servicii-salarizare-resurse-umane" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="button-primary is-small w-button">Detalii</a>
            </div>
            <div class="c-gridgallery1__image-2 img4"></div>
          </a>
          <a href="#" id="w-node-59df5b13134b-864b8345"
            class="link-block-2 w-inline-block">
            <div class="c-gridgallery1__content-2">
              <div class="on-dark text-medium weight-is-medium size-h2">Consultanta Fiscala</div>
              <a routerLink="/servicii-consultanta-fiscala-contabila" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="button-primary is-small w-button">Detalii</a>
            </div>
            <div class="c-gridgallery1__image-2 img6"></div>
          </a>
          <a href="#" id="w-node-59df5b131358-864b8345"
            class="link-block-2 w-inline-block">
            <div class="c-gridgallery1__content-2">
              <div class="on-dark text-medium weight-is-medium size-h2">Contabilitate PFA, II, IF</div>
              <a routerLink="/servicii-contabilitate-pfa-ii-if" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="button-primary is-small w-button">Detalii</a>
            </div>
            <div class="c-gridgallery1__image-2 img5"></div>
          </a>
        </div>
      </div>
    </div>
  </div>