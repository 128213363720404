<section class="gdpr">
  <div class="container">
    <div class="CookieDeclaration" lang="ro" dir="ltr">
      <p class="CookieDeclarationDialogText">Acest site utilizează cookie-uri. Folosim cookie-uri pentru a personaliza conținutul și anunțurile, pentru a oferi funcții de rețele sociale și pentru a analiza traficul. De asemenea, le oferim partenerilor de rețele sociale, de publicitate și de analize informații cu privire la modul în care folosiți site-ul nostru. Aceștia le pot combina cu alte informații oferite de dvs. sau culese în urma folosirii serviciilor lor. </p>
      <p class="CookieDeclarationIntro">Cookie-urile sunt mici fişiere de text ce pot fi utilizate de către site-urile web pentru a face utilizarea lor mai eficientă.<br style=""><br style="">Legea stipulează că putem stoca cookie-uri pe dispozitivul dvs., în cazul în care ele sunt strict necesare pentru operarea acestui site. Pentru toate celelalte tipuri de cookie-uri avem nevoie de permisiunea dvs.<br style=""><br style="">Acest site utilizează diferite tipuri de cookie-uri. Unele cookie-uri sunt plasate de către servicii părţi terţe care apar pe paginile noastre.<br style=""><br style="">Puteți, în orice moment, să modificați sau să vă retrageți acordul din Declarația privind modulele cookie de pe website-ul nostru.<br style=""><br style="">Aflați mai multe despre cine suntem, cum ne puteți contacta și cum procesăm datele personale în Politica noastră de confidențialitate.<br style=""><br style="">Când ne contactati în legatura cu consimtamântul dvs., va rugam sa precizati ID-ul si data consimtamântului dat.</p>
      <p>
      </p>
      <div id="CookieDeclarationUserStatusPanel" style="pointer-events: none; display: block; margin: 18px 0 0 0;margin-top: 12px; margin-bottom: 12px;">
        <div id="CookieDeclarationUserStatusLabel" style="display: inline-block">
          <div id="CookieDeclarationUserStatusLabelMulti" style="display: block;">Starea dumneavoastră actuală: Permitere toate&nbsp;(Necesare<span id="CookieDeclarationUserStatusLabelMultiSettingsPref" style="display: inline - block;">, Preferinţe</span><span id="CookieDeclarationUserStatusLabelMultiSettingsStat" style="display: inline - block;">, Statistici</span><span id="CookieDeclarationUserStatusLabelMultiSettingsMark" style="display: inline - block;">, Marketing</span>&nbsp;)</div>
          <p style="display:block"><span style="display:block">ID consimțământ: <span id="CookieDeclarationUserStatusLabelConsentId"></span></span><span style="display:block">Dată consimțământ: <span id="CookieDeclarationUserStatusLabelConsentDate"></span></span></p>
          <div id="CookieDeclarationUserStatusLabelOn" style="display: none;">Starea dumneavoastră actuală: Permitere toate</div>
        </div>
        <div id="CookieDeclarationChangeConsent" style="display: block"><a href="javascript:CookieConsent.renew();CookieDeclaration.SetUserStatusLabel();">Schimbați consimțământul dumneavoastră</a> | <span id="CookieDeclarationUserStatusLabelWithdraw" style="display: inline;"><a href="javascript:CookieConsent.withdraw();CookieDeclaration.SetUserStatusLabel();">Retragere consimțământ</a></span></div>
      </div><br><p class="CookieDeclarationLastUpdated">Declaraţie cookie-uri actualizată ultima oară în data de 24/04/2020 by <a href="https://www.cookiebot.com" target="_blank" rel="noopener" title="Cookiebot">Cookiebot</a>:
          </p>
      <div class="CookieDeclarationType" lang="ro" dir="ltr">
        <p class="CookieDeclarationTypeHeader">Necesare (1)
            </p>
        <p class="CookieDeclarationTypeDescription">Cookie-urile necesare ajută la a face un site utilizabil prin activarea funcţiilor de bază, precum navigarea în pagină şi accesul la zonele securizate de pe site. Site-ul nu poate funcţiona corespunzător fără aceste cookie-uri.</p>
        <table class="CookieDeclarationTable">
          <colgroup>
            <col style="width: 20%">
            <col style="width: 20%">
            <col style="width: 35%">
            <col style="width: 15%">
            <col style="width: 10%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col" class="CookieDeclarationTableHeader" dir="ltr">Denumire</th>
              <th scope="col" class="CookieDeclarationTableHeader" dir="ltr">Furnizor</th>
              <th scope="col" class="CookieDeclarationTableHeader" dir="ltr">Scop</th>
              <th scope="col" class="CookieDeclarationTableHeader" dir="ltr">Expirare</th>
              <th scope="col" class="CookieDeclarationTableHeader" dir="ltr">Tip</th>
            </tr>
          </thead>
          <tbody><tr>
            <td class="CookieDeclarationTableCell">CookieConsent</td>
            <td class="CookieDeclarationTableCell"><a href="https://www.cookiebot.com/goto/privacy-policy/" target="_blank" rel="noopener noreferrer nofollow" title="Politica de confidențialitate a Cookiebot" style="">Cookiebot</a></td>
            <td class="CookieDeclarationTableCell">Stores the user's cookie consent state for the current domain</td>
            <td class="CookieDeclarationTableCell">1 an</td>
            <td class="CookieDeclarationTableCell">HTTP Cookie</td>
          </tr>
        </tbody></table>
      </div>
    </div>
  </div>
</section>