<!-- Page Title -->
<section class="page-title" style="background-image: url('assets/images/blog-lukinstall.jpg')">
  <div class="auto-container">
    <h1>{{currentArticle.title}}</h1>
    
  </div>
</section>
<!-- End Page Title -->

<!-- Sidebar Page Container -->
<div class="sidebar-page-container style-two">
  <div class="auto-container">
    <div class="row clearfix">

      <!-- Content Side -->
      <div class="content-side col-lg-8 col-md-12 col-sm-12">
        <div class="blog-detail">
          <div class="inner-box">
            <div class="image">
              <img src="{{currentArticle.image}}" alt="" />
            </div>
            <div class="lower-content">
              <div class="article-conent" [innerHTML]="currentArticle.html"></div>


              <!-- Post Share Options-->
              <!-- <div class="post-share-options">
                <div class="post-share-inner">
                  
                  <ul class="social-box">
                    <span>Sharing Post:</span>
                    <li class="facebook"><a href="#"><span class="fa fa-facebook-f"></span></a></li>
                    <li class="twitter"><a href="#"><span class="fa fa-twitter"></span></a></li>
                    <li class="facebook"><a href="#"><span class="fa fa-google"></span></a></li>
                    <li class="twitter"><a href="#"><span class="fa fa-linkedin"></span></a></li>
                    <li class="linkedin"><a href="#"><span class="fa fa-envelope"></span></a></li>
                  </ul>
                </div>
              </div> -->

            </div>
          </div>


          <!-- Comment Form -->
          <!-- <div class="comment-form">

            <div class="group-title">
              <h3>Leave A Comment</h3>
              <div class="group-text">We’ll not publish your email address. Required fields are marked with *</div>
            </div>


            <form method="post" action="blog.html">
              <div class="row clearfix">

                <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input type="text" name="username" placeholder="Full Name" required>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input type="email" name="email" placeholder="Email" required>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                  <textarea class="" name="message" placeholder="Message"></textarea>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                  <button class="theme-btn btn-style-four" type="submit" name="submit-form"><span class="txt">post
                      comment</span></button>
                </div>

              </div>
            </form>

          </div> -->
         

        </div>
      </div>

      <!-- Sidebar Side -->
      <div class="sidebar-side col-lg-4 col-md-12 col-sm-12">
        <app-sidebar _nghost-bdt-c71="">
          <aside class="sidebar padding-right sticky-top">
            <div class="sidebar-widget categories-widget">
              <div class="widget-content">
                <div class="sidebar-title">
                  <h4>Servicii de Instalatii</h4>
                </div>
                <ul class="blog-cat">
                  <li><a routerlink="/instalator-sector-1" ng-reflect-router-link="/instalator-sector-1"
                      href="/instalator-sector-1">Instalator Sector 1</a>
                  </li>
                  <li><a routerlink="/instalator-sector-2" ng-reflect-router-link="/instalator-sector-2"
                      href="/instalator-sector-2">Instalator Sector 2</a>
                  </li>
                  <li><a routerlink="/instalator-sector-3" ng-reflect-router-link="/instalator-sector-3"
                      href="/instalator-sector-3">Instalator Sector 3</a>
                  </li>
                  <li><a routerlink="/instalator-sector-4" ng-reflect-router-link="/instalator-sector-4"
                      href="/instalator-sector-4">Instalator Sector 4</a>
                  </li>
                  <li><a routerlink="/instalator-sector-5" ng-reflect-router-link="/instalator-sector-5"
                      href="/instalator-sector-5">Instalator Sector 5</a>
                  </li>
                  <li><a routerlink="/instalator-sector-6" ng-reflect-router-link="/instalator-sector-6"
                      href="/instalator-sector-6">Instalator Sector 6</a>
                  </li>
                  <li><a routerlink="/instalator-non-stop" ng-reflect-router-link="/instalator-non-stop"
                      href="/instalator-non-stop">Instalator 24/7</a>
                  </li>
                  <li><a routerlink="/instalator-termic" ng-reflect-router-link="/instalator-termic"
                      href="/instalator-termic">Instalator Termic</a></li>
                  <li><a routerlink="/desfundare-scurgere" ng-reflect-router-link="/desfundare-scurgere"
                      href="/desfundare-scurgere">Desfundare Scurgere</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="sidebar-widget help-widget">
              <div class="widget-inner">
                <h3>Cauti un instalator?</h3>
                <div class="title">Suna sau trimite email</div><a href="tel:+40743697295"
                  class="phone">0743-697-295</a><a href="mailto:office@lukinstall.ro"
                  class="email">office@lukinstall.ro</a>
              </div>
            </div>

            <div class="sidebar-widget timing-widget schedule">
              <div class="widget-inner">
                <h3>Luk Install asigura instalatori in Bucuresti acoperire in toate sectoarele si
                  judetul Ilfov </h3>
                <ul>
                  <li><span>Program:</span></li>
                  <li><span>NON-STOP</span></li>
                  <li><span>7 zile din 7</span></li>
                  <li><span>24 ore din 24</span></li>
                </ul>
              </div>
            </div>

            <div class="sidebar-widget package-widget">
              <div class="widget-inner" style="background-image: url(/assets/images/instalator-urgente.jpg);">
                <h3>Urgenta ta este prioritatea noastra</h3>
                <div class="text">Intervenim rapid, oriunde in <br>
                  Bucuresti si Ilfov</div><a href="tel:+40743697295" class="theme-btn btn-style-one"><span
                    class="txt">Suna Non Stop</span></a>
              </div>
            </div>
          </aside>
        </app-sidebar>
      </div>

    </div>
  </div>
</div>
