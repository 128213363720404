<div class="page-wrapper">
  <!-- Preloader -->
  <!-- <div class="preloader"></div> -->

  <app-header></app-header>


  <router-outlet></router-outlet>
  <app-footer></app-footer>
  <app-whatsapp></app-whatsapp>
  <div class="scroll-to-top scroll-to-target" data-target="html"><span class="fa fa-arrow-up"></span></div>
</div>
