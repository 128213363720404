	<!-- Page Title -->
	<section class="page-title" style="background-image: url(/assets/images/desfundari-wc.jpg)">
        <div class="auto-container">
          <h1>Desfundare WC</h1>
          <ul class="page-breadcrumb" style="display: none">
            <li><a href="/">Acasa</a></li>
            <li>Desfundare WC</li>
          </ul>
        </div>
      </section>
      <!-- End Page Title -->
  
      <!-- About Section -->
      <div class="about-section">
        <div class="auto-container">
          <div class="row clearfix">
  
            <!-- Content Column -->
            <div class="content-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="title"><span class="icon"><img src="/assets/images/icons/cog.svg" alt="" /></span> Luk
                  Install - Instalator Urgente Bucuresti si Ilfov</div>
                <h2>Serviciu rapid si eficient de desfundare WC</h2>
                <div class="text">
                  <p>Asiguram servicii de desfundare WC, tevi si canalizare. Uneori, atunci cand incerci sa repari singur, situatia se poate inrautati. Cea mai eficienta solutie de desfundat WC-ul ramane Instalatorul profesionist. Suntem certificati ANRE si asiguram garantia lucrarilor.</p>
                  <p>
                    <strong>Folosim aparatura moderna pentru desfundarea vaselor de toaleta.
                    </strong>
                  </p>
                  <p>Echipa Luk Install beneficiaza de echipamente profesionale ce garanteaza o interventie sigura asupra tevilor, scurgerilor sau canalelor infundate.</p>
                  <ul>
                    <li>- Interventii rapide in caz de urgente</li>
                    <li>- Instalatori cu bogata experienta tehnica</li>
                    <li>- Preturi corecte pentru clienti</li>
  
                  </ul>
                </div>
                <div class="question">Suna un instalator urgente <a href="tel:+40743697295">0743-697-295</a></div>
              </div>
            </div>
  
            <!-- Images Column -->
            <div class="image-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="award-image">
                  <img src="/assets/images/desfundare-canalizare-urgente.png" alt="" />
                </div>
                <div class="image">
                  <img src="/assets/images/desfundare-wc.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End About Section -->
  
      <section class="featured-section-two">
        <div class="image-layer" style="background-image:url(assets/images/background/pattern-2.png)"></div>
        <div class="auto-container">
          <div class="inner-container">
  
            <div class="clearfix">
  
              <!-- Featured Section -->
              <section class="featured-section">
                <div class="auto-container">
                  <div class="inner-container">
  
                    <div class="clearfix">
  
                      <!-- Featured Block -->
                      <div class="featured-block col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-box">
                          <div class="content">
                            <div class="icon-box">
                              <img src="/assets/images/icons/shower.svg" alt="">
                            </div>
                            <h5><a href="#">Desfundare Cada</a></h5>
                          </div>
                        </div>
                      </div>
  
                      <!-- Featured Block -->
                      <div class="featured-block col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-box">
                          <div class="content">
                            <div class="icon-box">
                              <img src="/assets/images/icons/wc.svg" alt="">
                            </div>
                            <h5><a href="#">Desfundare WC</a></h5>
                          </div>
                        </div>
                      </div>
  
                      <!-- Featured Block -->
                      <div class="featured-block col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-box">
                          <div class="content">
                            <div class="icon-box">
                              <img src="/assets/images/icons/sink.svg" alt="">
                            </div>
                            <h5><a href="#">Desfundare Chiuveta</a></h5>
                          </div>
                        </div>
                      </div>
  
                    </div>
  
                  </div>
                </div>
              </section>
              <!-- End Featured Section -->
  
            </div>
  
          </div>
        </div>
      </section>
      <!-- Counter Section -->
      <section class="counter-section">
        <div class="auto-container">
  
          <!-- Fact Counter -->
          <div class="fact-counter">
            <div class="row clearfix">
  
              <!-- Column -->
              <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div class="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                  <div class="content">
                    <div class="count-outer count-box">
                      <span class="count-text" data-speed="3000" data-stop="268">0</span>+
                    </div>
                    <h4 class="counter-title">WC-uri desfundate</h4>
                  </div>
                </div>
              </div>
  
              <!-- Column -->
              <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div class="inner wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <div class="content">
                    <div class="count-outer count-box alternate">
                      <span class="count-text" data-speed="5000" data-stop="460">0</span>+
                    </div>
                    <h4 class="counter-title">Lucrari Instalatii Sanitare</h4>
                  </div>
                </div>
              </div>
  
              <!-- Column -->
              <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div class="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                  <div class="content">
                    <div class="count-outer count-box">
                      <span class="count-text" data-speed="2000" data-stop="15">0</span>+
                    </div>
                    <h4 class="counter-title">Ani de Experienta</h4>
                  </div>
                </div>
              </div>
  
              <!-- Column -->
              <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div class="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
                  <div class="content">
                    <div class="count-outer count-box">
                      <span class="count-text" data-speed="3500" data-stop="390">0</span>+
                    </div>
                    <h4 class="counter-title">Urgente Rezolvate</h4>
                  </div>
                </div>
              </div>
  
            </div>
          </div>
  
        </div>
      </section>
      <!-- End Counter Section -->
  
      <section class="featured-section-three">
        <div class="auto-container">
          <div class="inner-container">
            <h3>Desfundare WC in Bucuresti si Ilfov</h3>
            <div class="row clearfix">
  
              <!-- Left Column -->
              <div class="left-column col-lg-4 col-md-6 col-sm-12">
                <div class="inner-column">
  
                  <!-- Featured Block Three -->
                  <div class="featured-block-three">
                    <div class="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms"
                      style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInLeft;">
                      <div class="icon-box">
                        <span class="icon"><img src="assets/images/icons/pipe.svg" alt=""></span>
                      </div>
                      <h5><a>Desfundare canalizare cu sarpe electric</a></h5>
                      <div class="text">Intervenim asupra coloanelor de canalizare sau scurgere cu ajutorul unui utilaj
                        special conceput pentru astfel de probleme - <strong>sarpele electric</strong>.
                        Acesta este cel mai folosit echipament in cazul tevilor infundate sau scurgerilor cu dimensiuni
                        inguste.
                      </div>
                    </div>
                  </div>
  
  
                </div>
              </div>
  
              <!-- Icon Column -->
              <div class="icon-column col-lg-4 col-md-12 col-sm-12">
                <div class="inner-column">
                  <div class="circle-one"></div>
                  <div class="circle-two"></div>
                  <div class="circle-three"></div>
                  <div class="icon">
                    <img src="assets/images/icons/desfundare.svg" width="60%" alt="">
                  </div>
                </div>
              </div>
  
              <!-- Right Column -->
              <div class="right-column col-lg-4 col-md-6 col-sm-12">
                <div class="inner-column">
  
                  <!-- Featured Block Three -->
                  <div class="featured-block-three color-two style-two">
                    <div class="inner-box wow fadeInRight animated" data-wow-delay="0ms" data-wow-duration="1500ms"
                      style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInRight;">
                      <div class="icon-box">
                        <span class="icon"><img src="assets/images/icons/jammed-pipe.svg" alt=""></span>
                      </div>
                      <h5><a>Desfundare canalizare cu apa cu presiune</a></h5>
                      <div class="text">Robotul sub presiune asigura o desfundare optima in cazul canalelor infundate
                        asigurand si o curatare in prealabil a intregului sistem de scurgere.
                        Acest instrument functioneaza la o presiune maxima de 180 bari (cu apa calda sau apa rece – in
                        functie de situatie) reusind sa indeparteze toate rezidurile de pe coloanele de scurgere.</div>
                    </div>
                  </div>
  
  
  
                </div>
              </div>
  
            </div>
          </div>
        </div>
      </section>
  
      <section class="call-to-action-section-two"
        style="background-image: url(/assets/images/instalator-bucuresti-ilfov.jpg);">
        <div class="auto-container">
          <div class="content-boxed">
            <div class="inner-boxed">
              
              <h1>DESFUNDARE URGENTA WC?</h1>
              <div class="text">Uneori, nu ai cum sa eviti situatiile neplacute in care vasul de toaleta se infunda si devine nefunctional. 
                Dar le poti revolva simplu si fara batai de cap.
                Pentru astfel de situatii avem instalatori care vor interveni foarte rapid la 
                locul solicitarii, indiferent de gradul de infundare al instalatiilor de scurgere. 
                
                  </div>
              <div class="btns-box clearfix">
                <a href="/contact" class="theme-btn btn-style-one">
                  <span class="txt">
                     Cere oferta</span></a>
                <a href="/contact" class="talk">Suna un instalator <span>0743 697 295</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
  
  
  
      <section class="premium-section style-two">
        <div class="auto-container">
          <h2>Instalator Bucuresti si Ilfov</h2>
          <div class="row clearfix">
  
            <!-- Security Block -->
            <div class="security-block col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <a><img src="assets/images/resources/Montaj-Obiecte-Sanitare.jpg" alt=""></a>
                </div>
                <div class="lower-content">
                  <div class="hover-bg-color"></div>
                  <div class="upper-box">
  
                    <h5><a>Montaj Obiecte Sanitare</a></h5>
                  </div>
                  <div class="text">
                    <ul>
                      <li><span>></span> Chiuvete</li>
                      <li><span>></span> Lavuaruri</li>
                      <li><span>></span> Cazi de baie</li>
                      <li><span>></span> Cabine de dus</li>
                      <li><span>></span> Vase de WC</li>
                      <li><span>></span> Mașini de spălat vase</li>
                      <li><span>></span> Masini de spalat rufe</li>
                      <li><span>></span> Mașini de uscat rufe</li>
                      <li><span>></span> Boilere </li>
                      <li><span>></span> Centrale termice</li>
                    </ul>
  
  
  
                  </div>
                </div>
              </div>
            </div>
  
            <!-- Security Block -->
            <div class="security-block col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <a><img src="assets/images/resources/Montaj-Profesional.jpg" alt=""></a>
                </div>
                <div class="lower-content">
                  <div class="hover-bg-color"></div>
                  <div class="upper-box">
  
                    <h5><a>Montaj Profesional</a></h5>
                  </div>
                  <div class="text">
                    <ul>
                      <li><span>></span> Baterii cazi</li>
                      <li><span>></span> Chiuvete, bideuri</li>
                      <li><span>></span> Centrale termice</li>
                      <li><span>></span> Calorifere</li>
                      <li><span>></span> Robineti de trecere</li>
                      <li><span>></span> Robineti de calorifere</li>
                      <li><span>></span> Hote</li>
                      <li><span>></span> Cuptoare</li>
                      <li><span>></span> Apometre</li>
                      <li><span>></span> Filtre de apa</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- Security Block -->
            <div class="security-block col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <a><img src="assets/images/resources/Servicii-Complete.jpg" alt=""></a>
                </div>
                <div class="lower-content">
                  <div class="hover-bg-color"></div>
                  <div class="upper-box">
                    <h5><a>Servicii Complete</a></h5>
                  </div>
                  <div class="text">
                    <ul>
                      <li><span>></span> Desfundare profesionala</li>
                      <li><span>></span> Desfundare canalizare</li>
                      <li><span>></span> Instalații electrice (Autorizat ANR)</li>
                      <li><span>></span> Desfundari coloane comune</li>
                      <li><span>></span> Înlocuire coloane orizontale subsoluri</li>
                      <li><span>></span> Desfundari coloane comune</li>
                      <li><span>></span> Desfundare canalizare</li>
                      <li><span>></span> Inlocuire tevi sparte</li>
                      <li><span>></span> Inlocuire tablouri electrice</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
  
          </div>
  
        </div>
      </section>
      <section class="call-to-action-section">
        <div class="auto-container">
          <div class="row clearfix">
            <!-- Title Column -->
            <div class="title-column col-lg-8 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="phone flaticon-call-3"></div>
                <div class="text"><span>Suna un instalator Bucuresti si Ilfov pentru servicii rapide si de durata.
                    <br></span> Telefon: <span>0743-697-295</span></div>
              </div>
            </div>
            <!-- Button Column -->
            <div class="button-column col-lg-4 col-md-12 col-sm-12">
              <div class="inner-column">
                <a href="/contact" class="theme-btn btn-style-two"><span class="txt"> CERE
                    OFERTA</span></a>
              </div>
            </div>
          </div>
        </div>
      </section>
  