<!-- Page Title -->
<section class="page-title" style="background-image: url('assets/images/blog-lukinstall.jpg')">
  <div class="auto-container">
    <h1>blog</h1>
   
  </div>
</section>
<!-- End Page Title -->

<!-- Sidebar Page Container -->
<div class="sidebar-page-container style-two">
  <div class="auto-container">
    <div class="row clearfix">

      <!-- Content Side -->
      <div class="content-side col-lg-8 col-md-12 col-sm-12">
        <div class="blog-classic">

          <!-- News Block Two -->
          <div class="news-block-two" *ngFor="let d of data | paginate: { itemsPerPage: 7, currentPage: p }">
            <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
              <div class="image">
                <a routerLink="{{d.link}}"><img src="{{d.image}}" alt="" /></a>
              </div>
              <div class="lower-content">
                <!-- <ul class="post-meta">
                  <li><span class="icon flaticon-user"></span>Admin</li>
                  <li><span class="icon flaticon-calendar-1"></span>Dec 20, 2019</li>
                  <li><span class="icon flaticon-comment"></span>Comments (43)</li>
                </ul> -->
                <h3><a routerLink="{{d.link}}">{{d.title}}</a></h3>
                <div class="text">{{d.description}}</div>
                <a class="theme-btn btn-style-three" routerLink="{{d.link}}"><span class="txt">Citeste mai mult &nbsp;<i
                      class="fa fa-angle-right"></i></span></a>
              </div>
            </div>
          </div>



          <pagination-controls (pageChange)="p = $event" class="float-right"></pagination-controls>


          <!-- Post Share Options -->
          <!-- <div class="pagination-outer">
            <div class="styled-pagination">
              <ul class="clearfix">
                <li class="active prev"><a href="#">Prev &ensp; </a></li>
                <li><a href="#">1</a></li>
                <li><a href="#">2</a></li>
                <li><a href="#">3</a></li>
                <li><a href="#">...</a></li>
                <li><a href="#">6</a></li>
                <li class="next"><a href="#">&ensp; Next</a></li>
              </ul>
            </div>
          </div> -->

        </div>
      </div>

      <!-- Sidebar Side -->
      <div class="sidebar-side col-lg-4 col-md-12 col-sm-12">
        <app-sidebar _nghost-bdt-c71="">
          <aside class="sidebar padding-right sticky-top">
            <div class="sidebar-widget categories-widget">
              <div class="widget-content">
                <div class="sidebar-title">
                  <h4>Servicii de Instalatii</h4>
                </div>
                <ul class="blog-cat">
                  <li><a routerlink="/instalator-sector-1"
                      ng-reflect-router-link="/instalator-sector-1" href="/instalator-sector-1">Instalator Sector 1</a>
                  </li>
                  <li><a routerlink="/instalator-sector-2"
                      ng-reflect-router-link="/instalator-sector-2" href="/instalator-sector-2">Instalator Sector 2</a>
                  </li>
                  <li><a routerlink="/instalator-sector-3"
                      ng-reflect-router-link="/instalator-sector-3" href="/instalator-sector-3">Instalator Sector 3</a>
                  </li>
                  <li><a routerlink="/instalator-sector-4"
                      ng-reflect-router-link="/instalator-sector-4" href="/instalator-sector-4">Instalator Sector 4</a>
                  </li>
                  <li><a routerlink="/instalator-sector-5"
                      ng-reflect-router-link="/instalator-sector-5" href="/instalator-sector-5">Instalator Sector 5</a>
                  </li>
                  <li><a routerlink="/instalator-sector-6"
                      ng-reflect-router-link="/instalator-sector-6" href="/instalator-sector-6">Instalator Sector 6</a>
                  </li>
                  <li><a routerlink="/instalator-non-stop"
                      ng-reflect-router-link="/instalator-non-stop" href="/instalator-non-stop">Instalator 24/7</a>
                  </li>
                  <li><a routerlink="/instalator-termic"
                      ng-reflect-router-link="/instalator-termic" href="/instalator-termic">Instalator Termic</a></li>
                  <li><a routerlink="/desfundare-scurgere"
                      ng-reflect-router-link="/desfundare-scurgere" href="/desfundare-scurgere">Desfundare Scurgere</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="sidebar-widget help-widget">
              <div class="widget-inner">
                <h3>Cauti un instalator?</h3>
                <div class="title">Suna sau trimite email</div><a
                  href="tel:+40743697295" class="phone">0743-697-295</a><a
                  href="mailto:office@lukinstall.ro" class="email">office@lukinstall.ro</a>
              </div>
            </div>
           
            <div class="sidebar-widget timing-widget schedule">
              <div class="widget-inner">
                <h3>Luk Install asigura instalatori in Bucuresti acoperire in toate sectoarele si
                  judetul Ilfov </h3>
                <ul>
                  <li><span>Program:</span></li>
                  <li><span>NON-STOP</span></li>
                  <li><span>7 zile din 7</span></li>
                  <li><span>24 ore din 24</span></li>
                </ul>
              </div>
            </div>
            
            <div class="sidebar-widget package-widget">
              <div class="widget-inner"
                style="background-image: url(/assets/images/instalator-urgente.jpg);">
                <h3>Urgenta ta este prioritatea noastra</h3>
                <div class="text">Intervenim rapid, oriunde in <br>
                  Bucuresti si Ilfov</div><a href="tel:+40743697295"
                  class="theme-btn btn-style-one"><span class="txt">Suna Non Stop</span></a>
              </div>
            </div>
          </aside>
        </app-sidebar>
      </div>

    </div>
  </div>
</div>

<section class="call-to-action-section-two"
  style="background-image: url(/assets/images/instalator-bucuresti-ilfov.jpg)">
  <div class="auto-container">
    <!-- Content Boxed -->
    <div class="content-boxed">
      <div class="inner-boxed">
        <div class="title">CU NOI, AI DOAR DE CASTIGAT!</div>
        <h1>Suntem partener ROMSTAL</h1>
        <div class="text">Beneficiati pana la 25% reducere pentru toate materialele Romstal</div>
        <div class="btns-box clearfix">
          <a routerLink="/contact" class="theme-btn btn-style-one"><span class="txt">
              Cere oferta</span></a>
          <a routerLink="/contact" class="talk">Suna un instalator <span>0743 697 295</span></a>
        </div>
      </div>
    </div>
  </div>
</section>
