<div class="section">
  <div class="container">
    <div class="row">
      <div class="col block-centered col-lg-6 col-md-6 xs-12 col-12">
        <h1>EROARE 404</h1>
        <h4>Ops!
          Se pare ca nu am gasit pagina.
          Intoarce-te pe <a routerLink="/">pagina principala</a></h4>



      </div>

      <div class="col block-centered col-lg-6 col-md-6 xs-12 col-12">

        <img src="/assets/images/eroare-404-instalator-bucuresti.png" alt="">

      </div>
    </div>

  </div>
</div>
