<div class="section is-hero has-gradient position-relative overflow-hidden is-subpage">
    <div class="container position-relative">
        <div class="col block-centered text-align-center lg-7 md-12">
            <h1>Despre noi</h1>
            <div class="padding-left padding-right margin-bottom is-heading-color">Inovatia si simplitatea ne fac fericiti: obiectivul nostru este sa eliminam barierele tehnice sau birocratice care pot impiedica proprietarii de afaceri sa-si indeplineasca obiectivele de business. </div>
        </div>
    </div><img src="/assets/images/header2.svg" alt="" class="header-image style3">
</div>


<div class="section">
    <div class="container">
        <div class="col block-centered text-align-center lg-6 md-12">
            <h2>Suntem o firma de contabilitate 
                din Bucuresti
                </h2>
            <div class="text-medium low-text-contrast margin-bottom">In echipa noastra veti gasi doar profesionisti, contabili, experti contabili si experti financiari, fiecare aducandu-si aportul. Astfel, putem sa oferim colaboratorilor nostri solutii complete pentru cele mai dificile aspecte.</div>
        </div>
    </div>
    <div class="container is-wrapping">
        <div class="col lg-4 md-6 sm-12">
            <div class="flex-horizontal"><img src="/assets/images/number-1_1.svg" width="64" alt="" class="alignself-top">
                <div class="margin-left">
                    <h3>Experti Contabili</h3>
                    
                </div>
            </div>
        </div>
        <div class="col lg-4 md-6 sm-12">
            <div class="flex-horizontal"><img src="/assets/images/icon11_1.svg" width="64" alt="" class="alignself-top">
                <div class="margin-left">
                    <h3>Consultanti Fiscali
                    </h3>
                   
                </div>
            </div>
        </div>
        <div class="col lg-4 md-6 sm-12">
            <div class="flex-horizontal"><img src="/assets/images/icon5_1.svg" width="64" alt="" class="alignself-top">
                <div class="margin-left">
                    <h3>Antreprenori</h3>
                   
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section no-padding-bottom has-bg-accent">
    <div class="container flexh-align-center">
        <div class="col lg-12 no-padding-lr">
            <div data-delay="3000" data-animation="slide" data-autoplay="1" data-easing="ease-out-quint" data-duration="1500"
            data-infinite="1" class="c-slider w-slider">
            <div class="w-slider-mask">
              <div class="w-slide">
                <div class="container flexh-align-center">
                  <!-- <div class="col no-margin-bottom lg-6 md-12 order-last"><img
                      src="https://via.placeholder.com/1000x600.png?text=IMAGE" width="320" alt=""></div>
                  <div class="col lg-1"></div> -->
                  <div class="col no-margin-bottom lg-8 position-relative md-12">
                    <div class="text-large weight-is-thin margin-bottom">Recomand cu cea mai mare incredere firma CERT
                      ACCOUNT pentru serviciile de contabilitate si personal. Aici am intalnit oameni seriosi care isi fac
                      treaba cu profesionalism.</div>
                    <!-- <div class="is-bold">Johny Baldon</div>
                    <div class="text-small">CEO at Turus Berkarya</div> -->
                    <div class="c-quote__marker">“</div>
                  </div>
                </div>
              </div>
              <div class="w-slide">
                <div class="container flexh-align-center">
                  <!-- <div class="col no-margin-bottom lg-6 md-12 order-last"><img
                      src="https://via.placeholder.com/1000x600.png?text=IMAGE" alt=""></div> -->
                  <!-- <div class="col lg-1"></div> -->
                  <div class="col no-margin-bottom lg-8 position-relative md-12">
                    <div class="text-large weight-is-thin margin-bottom"> De-a lungul anilor am simtit ca avem in CERT
                      ACCOUNT nu doar un simplu furnizor de servicii contabile, ci un adevarat partener care ne-a ajutat cu
                      mult profesionalism in etapele de dezvoltare ale firmei noastre.</div>
                    <!-- <div class="is-bold">Christian Soilo</div>
                    <div class="text-small">Managing Director @Semanding</div> -->
                    <div class="c-quote__marker">“</div>
                  </div>
                </div>
              </div>
              <div class="w-slide">
                <div class="container flexh-align-center">
                  <!-- <div class="col no-margin-bottom lg-6 md-12 order-last"><img
                      src="https://via.placeholder.com/1000x600.png?text=IMAGE" width="287" alt=""></div>
                  <div class="col lg-1"></div> -->
                  <div class="col no-margin-bottom lg-8 position-relative md-12">
                    <div class="text-large weight-is-thin margin-bottom">Calitatea ireprosabila a serviciilor prestate,
                      raportul calitate-pret, promptitudinea si profesionalismul de care au dat dovada in abordarea
                      lucrarilor ne-a adus consideratia fata de aceasta societate. Recomand cu incredere CERT ACCOUNT celor
                      care vor cel mai bun contabil.
                    </div>
                    <!-- <div class="is-bold">Baharuddin Muamari</div>
                    <div class="text-small">Executive Producer @Joyoboyo</div> -->
                    <div class="c-quote__marker">“</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="hidden w-slider-arrow-left">
              <div class="w-icon-slider-left"></div>
            </div>
            <div class="hidden w-slider-arrow-right">
              <div class="w-icon-slider-right"></div>
            </div>
            <div class="c-slider__nav w-slider-nav w-slider-nav-invert w-round"></div>
          </div>
        </div>
       
    </div>
</div>

<div class="section has-bg-image2 position-relative overflow-hidden">
    <div class="container">
        <div class="col lg-12">
            <div class="c-cta1 on-dark">
                <div class="md-margin-bottom cta-text">
                    <div class="size-h2 on-dark">Intra in legatura cu noi pentru servicii de contabilitate performante!</div>
                    <div>Contacteaza-ne telefonic sau prin e-mail.</div>
                </div>
                <div class="basis400">
                    <a href="tel:+40739913168" class="button-primary margin-right text-align-center is-white w-inline-block">
                       Suna
                    </a>
                    <a href="/contact" routerLink="/contact" routerLinkActive="active" class="button-primary is-ghost on-dark w-inline-block">
                        Trimite email
                    </a>
                </div>
            </div>
        </div>
    </div
    ><img src="/assets/images/header2.svg" alt="" class="header-image position2">
</div>