<!-- Page Title -->
<section class="page-title" style="background-image: url(/assets/images/instalator-sector-2.jpg)">
  <div class="auto-container">
    <h1>Instalator Sector 2</h1>
    <ul class="page-breadcrumb" style="display: none">
      <li><a href="/">Acasa</a></li>
      <li>Instalator Sector 2</li>
    </ul>
  </div>
</section>
<!-- End Page Title -->
<section class="premium-section style-two">
  <div class="auto-container">
    <h2>Instalator Bucuresti si Ilfov</h2>
    <div class="row clearfix">

      <!-- Security Block -->
      <div class="security-block col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a><img src="assets/images/resources/Montaj-Obiecte-Sanitare.jpg" alt=""></a>
          </div>
          <div class="lower-content">
            <div class="hover-bg-color"></div>
            <div class="upper-box">

              <h5><a>Montaj Obiecte Sanitare</a></h5>
            </div>
            <div class="text">
              <ul>
                <li><span>></span> Chiuvete</li>
                <li><span>></span> Lavuaruri</li>
                <li><span>></span> Cazi de baie</li>
                <li><span>></span> Cabine de dus</li>
                <li><span>></span> Vase de WC</li>
                <li><span>></span> Mașini de spălat vase</li>
                <li><span>></span> Masini de spalat rufe</li>
                <li><span>></span> Mașini de uscat rufe</li>
                <li><span>></span> Boilere </li>
                <li><span>></span> Centrale termice</li>
              </ul>



            </div>
          </div>
        </div>
      </div>

      <!-- Security Block -->
      <div class="security-block col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a><img src="assets/images/resources/Montaj-Profesional.jpg" alt=""></a>
          </div>
          <div class="lower-content">
            <div class="hover-bg-color"></div>
            <div class="upper-box">

              <h5><a>Montaj Profesional</a></h5>
            </div>
            <div class="text">
              <ul>
                <li><span>></span> Baterii cazi</li>
                <li><span>></span> Chiuvete, bideuri</li>
                <li><span>></span> Centrale termice</li>
                <li><span>></span> Calorifere</li>
                <li><span>></span> Robineti de trecere</li>
                <li><span>></span> Robineti de calorifere</li>
                <li><span>></span> Hote</li>
                <li><span>></span> Cuptoare</li>
                <li><span>></span> Apometre</li>
                <li><span>></span> Filtre de apa</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Security Block -->
      <div class="security-block col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a><img src="assets/images/resources/Servicii-Complete.jpg" alt=""></a>
          </div>
          <div class="lower-content">
            <div class="hover-bg-color"></div>
            <div class="upper-box">
              <h5><a>Servicii Complete</a></h5>
            </div>
            <div class="text">
              <ul>
                <li><span>></span> Desfundare profesionala</li>
                <li><span>></span> Desfundare canalizare</li>
                <li><span>></span> Instalații electrice (Autorizat ANR)</li>
                <li><span>></span> Desfundari coloane comune</li>
                <li><span>></span> Înlocuire coloane orizontale subsoluri</li>
                <li><span>></span> Desfundari coloane comune</li>
                <li><span>></span> Desfundare canalizare</li>
                <li><span>></span> Inlocuire tevi sparte</li>
                <li><span>></span> Inlocuire tablouri electrice</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</section>
<section class="security-section">
  <div class="auto-container">
    <div class="row clearfix">
      <div class="content-column col-lg-6 col-md-12 col-sm-12">
        <div class="inner-column">
          <div class="title">
            <span class="icon">
              <img src="/assets/images/icons/cog.svg" alt="">
            </span>
            Instalator Bucuresti si Ilfov
          </div>
          <h2>Garantam orice lucrare prin Certificat de Garantie </h2>

          <div class="text df">
            <p>Apeleaza cu incredere la serviciile noastre pentru orice tip de
              lucrari.</p>
            <ul>
              <li>Colentina (Bulevard)</li>
              <li>Grigore Moisil</li>
              <li>Maica Domnului</li>
              <li>Teiul Doamnei</li>
              <li>Lacu Tei</li>
              <li>Andronache</li>
            </ul>
            <ul>
                <li>Bacila</li>
                <li>Petricani</li>
                <li>Stefan cel Mare</li>
                <li>Fundeni</li>
                <li>Lizeanu</li>
                <li>Doamna Ghica</li>
            </ul>
          </div>
          <div class="bold-text">Suna un instalator: <span class="green">0743-697-295</span></div>
        </div>
      </div>
      <div class="images-column col-lg-6 col-md-12 col-sm-12">
        <div class="inner-column">
          <img src="/assets/images/instalator-sector-2.png" alt="instalator-non-stop-sector-2">
          <div class="bold-text">Ne deplasam GRATUIT si RAPID in sector 2</div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="premium-section">
  <div class="auto-container">
    <div class="row clearfix">
      <div class="image-column col-lg-4 col-md-12 col-sm-12">
        <img src="/assets/images/instalator-non-stop-sector-1.png" alt="instalator-non-stop-sector-2">
      </div>
      <div class="title-column col-lg-8 col-md-12 col-sm-12">
        <h3>INSTALATOR 24/7</h3>
        <p>Asiguram un timp de raspuns cat mai eficient pentru a
          solutiona cat mai repede toate problemele aparute la nivelul instalatiilor.
        </p>
      </div>
    </div>
  </div>
</section>
<section class="security-section">
  <div class="auto-container">
    <div class="row clearfix">

      <div class="images-column col-lg-6 col-md-12 col-sm-12">
        <div class="inner-column">
          <img src="/assets/images/instalator-sector-2-preview.png" alt="instalator-non-stop-sector-2">
        </div>
      </div>
      <div class="content-column col-lg-6 col-md-12 col-sm-12">
        <div class="inner-column urgency">

          <h2>Ai o urgenta? </h2>

          <div class="text df">
            <p>Contacteaza un instalator:</p>
            <ul>
              <li><a href="tel:+40743697295"><i class="icon flaticon-call-3"></i> 0743-697-295</a></li>
              <li><a href="tel:+40732831826"><i class="icon flaticon-call-3"></i> 0732-831-826</a></li>
              <li><a href="tel:+40771506190"><i class="icon flaticon-call-3"></i> 0771-506-190</a></li>
              <li><a href="mailto:office@lukinstall.ro"><i class="icon flaticon-email-4"></i> office@lukinstall.ro</a>
              </li>
            </ul>
          </div>
          <p>Si venim rapid sa salvam situatia!</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="call-to-action-section-two"
  style="background-image: url(/assets/images/instalator-bucuresti-ilfov.jpg)">
  <div class="auto-container">
    <!-- Content Boxed -->
    <div class="content-boxed">
      <div class="inner-boxed">
        <div class="title">CU NOI, AI DOAR DE CASTIGAT!</div>
        <h1>Suntem partener ROMSTAL</h1>
        <div class="text">Beneficiati pana la 25% reducere pentru toate materialele Romstal</div>
        <div class="btns-box clearfix">
          <a routerLink="/contact" class="theme-btn btn-style-one"><span class="txt">
              Cere oferta</span></a>
          <a routerLink="/contact" class="talk">Suna un instalator <span>0743 697 295</span></a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="security-section last-section">
  <div class="auto-container">
    <div class="row clearfix">

      <div class="col-lg-12 col-md-12 col-sm-12">
        <p>Toti <strong>instalatorii nostri sunt calificati</strong> pentru a efectua lucrari complexe de instalatii.
          Avem o <strong>echipa de incredere</strong>, cu experienta dovedita in industria de instalatii sanitare si
          termice.
        </p>
        <p>Ne deplasam rapid, oriunde in <strong>Bucuresti si Ilfov.</strong></p>
      </div>

    </div>
  </div>
</section>
