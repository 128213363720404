<!-- Main Header-->
<header class="main-header header-style-one">


  <!--Header-Upper-->
  <div class="header-upper">
    <div class="auto-container">
      <div class="inner-container clearfix">

        <div class="pull-left logo-box">
          <div class="logo"><a routerLink="/"><img src="/assets/images/logo.png" alt="" title=""></a>
          </div>
        </div>

        <div class="nav-outer clearfix">
          <!--Mobile Navigation Toggler-->
          <div class="mobile-nav-toggler" (click)="mobileToggler()"><span class="icon flaticon-menu"></span></div>
          <!-- Main Menu -->
          <nav class="main-menu navbar-expand-md">
            <div class="navbar-header">
              <!-- Toggle Button -->
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
            </div>

            <div class="navbar-collapse collapse clearfix" id="navbarSupportedContent">
              <ul class="navigation clearfix">
                <li class="current"><a (click)="activeLink($event, 'parent', '/')">Acasa</a></li>
                <li class="dropdown"><a (click)="activeLink($event, 'parent', '/instalator-bucuresti')">Instalator Bucuresti</a>
                  <ul>
                    <li><a (click)="activeLink($event, 'child', '/instalator-sector-1')">Instalator Sector 1</a></li>
                    <li><a (click)="activeLink($event, 'child', '/instalator-sector-2')">Instalator Sector 2</a></li>
                    <li><a (click)="activeLink($event, 'child', '/instalator-sector-3')">Instalator Sector 3</a></li>
                    <li><a (click)="activeLink($event, 'child', '/instalator-sector-4')">Instalator Sector 4</a></li>
                    <li><a (click)="activeLink($event, 'child', '/instalator-sector-5')">Instalator Sector 5</a></li>
                    <li><a (click)="activeLink($event, 'child', '/instalator-sector-6')">Instalator Sector 6</a></li>
                  </ul>
                </li>
                <li class="dropdown"><a (click)="activeLink($event, 'parent', '/instalator-sanitar')">Instalator Sanitar</a>
                  <ul>
                    <li><a (click)="activeLink($event, 'child', '/desfundare-canalizare')">Desfundare Canalizare</a></li>
                    <li><a (click)="activeLink($event, 'child', '/desfundare-wc')">Desfundare WC</a></li>
                  </ul>
                </li>
  
                <li class="dropdown"><a (click)="activeLink($event, 'parent', '/instalatii-termice')">Instalatii Termice</a>
                  <ul>
                    <li><a (click)="activeLink($event, 'child', '/montaj-centrale-termice')">Montaj Centrale Termice</a></li>
                  </ul>
                </li>
                <li><a (click)="activeLink($event, 'parent', '/instalator-non-stop')">Instalator 24/7</a></li>
                <li><a (click)="activeLink($event, 'parent', '/lucrari')">Lucrari</a></li>
                <li><a (click)="activeLink($event, 'parent', '/contact')">Contact</a></li>
              </ul>
            </div>
          </nav>

          <!-- Main Menu End-->
          <div class="outer-box clearfix">




          </div>
        </div>

      </div>

    </div>
  </div>
  <!--End Header Upper-->

  <!-- Sticky Header  -->
  <div class="sticky-header">
    <div class="auto-container clearfix">
      <!--Logo-->
      <div class="logo pull-left">
        <a routerLink="/" title=""><img src="/assets/images/logo.png" alt="" title=""></a>
      </div>
      <!--Right Col-->
      <div class="pull-right">
        <!-- Main Menu -->
        <nav class="main-menu">
          <div class="navbar-collapse collapse clearfix" id="navbarSupportedContent">
            <ul class="navigation clearfix">
              <li class="current"><a (click)="activeLink($event, 'parent', '/')">Acasa</a></li>
              <li class="dropdown"><a (click)="activeLink($event, 'parent', '/instalator-bucuresti')">Instalator Bucuresti</a>
                <ul>
                  <li><a (click)="activeLink($event, 'child', '/instalator-sector-1')">Instalator Sector 1</a></li>
                  <li><a (click)="activeLink($event, 'child', '/instalator-sector-2')">Instalator Sector 2</a></li>
                  <li><a (click)="activeLink($event, 'child', '/instalator-sector-3')">Instalator Sector 3</a></li>
                  <li><a (click)="activeLink($event, 'child', '/instalator-sector-4')">Instalator Sector 4</a></li>
                  <li><a (click)="activeLink($event, 'child', '/instalator-sector-5')">Instalator Sector 5</a></li>
                  <li><a (click)="activeLink($event, 'child', '/instalator-sector-6')">Instalator Sector 6</a></li>
                </ul>
              </li>
              <li class="dropdown"><a (click)="activeLink($event, 'parent', '/instalator-sanitar')">Instalator Sanitar</a>
                <ul>
                  <li><a (click)="activeLink($event, 'child', '/desfundare-canalizare')">Desfundare Canalizare</a></li>
                  <li><a (click)="activeLink($event, 'child', '/desfundare-wc')">Desfundare WC</a></li>
                </ul>
              </li>

              <li class="dropdown"><a (click)="activeLink($event, 'parent', '/instalatii-termice')">Instalatii Termice</a>
                <ul>
                  <li><a (click)="activeLink($event, 'child', '/montaj-centrale-termice')">Montaj Centrale Termice</a></li>
                </ul>
              </li>
              <li><a (click)="activeLink($event, 'parent', '/instalator-non-stop')">Instalator 24/7</a></li>
              <li><a (click)="activeLink($event, 'parent', '/lucrari')">Lucrari</a></li>
              <li><a (click)="activeLink($event, 'parent', '/contact')">Contact</a></li>
            </ul>
          </div>
        </nav><!-- Main Menu End-->

      </div>
    </div>
  </div><!-- End Sticky Menu -->

  <!-- Mobile Menu  -->
  <div class="mobile-menu">
    <div class="menu-backdrop"></div>
    <div class="close-btn" (click)="mobileToggler()"><span class="icon flaticon-multiply"></span></div>

    <nav class="menu-box">
      <div class="nav-logo"><a routerLink="/"><img src="/assets/images/logo.png" alt="" title=""></a>
      </div>
      <div class="menu-outer">
        <div class="navbar-collapse collapse clearfix" id="navbarSupportedContent">
          <ul class="navigation clearfix">
            <li class="current"><a (click)="navigateSubmenu('/')" routerLink="/">Acasa</a></li>
            <li class="dropdown"><a (click)="navigateSubmenu('/instalator-bucuresti')"
                routerLink="/instalator-bucuresti">Instalator Bucuresti</a>
              <ul class="submenu" [@toggleHeight]="navigationSubState.InstalatorBucuresti">
                <li><a (click)="navigateSubmenu('/instalator-sector-1')" routerLink="/instalator-sector-1">Instalator
                    Sector 1</a></li>
                <li><a (click)="navigateSubmenu('/instalator-sector-2')" routerLink="/instalator-sector-2">Instalator
                    Sector 2</a></li>
                <li><a (click)="navigateSubmenu('/instalator-sector-3')" routerLink="/instalator-sector-3">Instalator
                    Sector 3</a></li>
                <li><a (click)="navigateSubmenu('/instalator-sector-4')" routerLink="/instalator-sector-4">Instalator
                    Sector 4</a></li>
                <li><a (click)="navigateSubmenu('/instalator-sector-5')" routerLink="/instalator-sector-5">Instalator
                    Sector 5</a></li>
                <li><a (click)="navigateSubmenu('/instalator-sector-6')" routerLink="/instalator-sector-6">Instalator
                    Sector 6</a></li>
              </ul>
              <div class="dropdown-btn" (click)="toggleSubmenu($event, 'InstalatorBucuresti')"><span class="fa fa-angle-down"></span></div>
            </li>
            <li class="dropdown"><a (click)="navigateSubmenu('/instalator-sanitar')"
                routerLink="/instalator-sanitar">Instalator Sanitar</a>
              <ul class="submenu" [@toggleHeight]="navigationSubState.InstalatorSanitar">
                <li><a (click)="navigateSubmenu('/desfundare-canalizare')" routerLink="/desfundare-canalizare">Desfundare
                    Canalizare</a></li>
                <li><a (click)="navigateSubmenu('/desfundare-wc')" routerLink="/desfundare-wc">Desfundare WC</a></li>
              </ul>
              <div class="dropdown-btn" (click)="toggleSubmenu($event, 'InstalatorSanitar')"><span class="fa fa-angle-down"></span></div>
            </li>

            <li class="dropdown"><a (click)="navigateSubmenu('/instalatii-termice')"
                routerLink="/instalatii-termice">Instalatii Termice</a>
              <ul class="submenu" [@toggleHeight]="navigationSubState.InstalatiiTermice">
                <li><a (click)="navigateSubmenu('/montaj-centrale-termice')" routerLink="/montaj-centrale-termice">Montaj
                    Centrale Termice</a></li>
              </ul>
              <div class="dropdown-btn" (click)="toggleSubmenu($event, 'InstalatiiTermice')"><span class="fa fa-angle-down"></span></div>
            </li>
            <li><a (click)="navigateSubmenu('/instalator-non-stop')" routerLink="/instalator-non-stop">Instalator Non
                Stop</a></li>
            <li><a (click)="navigateSubmenu('/lucrari')" routerLink="/lucrari">Lucrari</a></li>
            <li><a (click)="navigateSubmenu('/contact')" routerLink="/contact">Contact</a></li>
          </ul>
        </div>
      </div>
    </nav>
  </div><!-- End Mobile Menu -->

</header>
<!-- End Main Header -->
