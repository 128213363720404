
<div class="section padding-bottom-extra">
  <div class="container flex-horizontal">
    <div class="col lg-12 md-12 no-margin-bottom-lg">
      <div class="sidebar-page-container">
        <p class="c4"><span class="c2">TERMENI SI CONDITII </span></p>
        <p class="c4"><span class="c2">Termenii şi condiţiile de utilizare a paginii de internet disponibilă la adresa
            www.lukinstall.ro (denumit în continuare "Site")</span></p>
        <p class="c4"><span class="c2">1. PRIN UTILIZAREA ACESTUI WEBSITE, SUNTEȚI DE ACORD CU ACESTE CONDIȚII DE
            UTILIZARE</span></p>
        <p class="c3"><span class="c12 c0">Acest website reprezintă proprietatea LUK-INSTALL PERFECT 24 SRL, denumit in
            continuare “Furnizor”</span></p>
        <p class="c3"><span class="c2">Accesul și utilizarea acestui website sunt supuse următoarilor termeni și
            condiții de utilizare și tuturor legilor și regulamentelor aplicabile. </span></p>
        <p class="c3"><span>Accesând și utilizând website-ul, acceptați, fără limitări sau calificări, aceste condiții
            și luați la cunoștință ca orice alte acorduri între dvs. și </span><span class="c0">LUK-INSTALL PERFECT 24
            SRL</span><span class="c2">&nbsp;sunt înlocuite prin prevederile prezentelor termene și condiții. </span>
        </p>
        <p class="c3"><span class="c2">Dacă nu sunteți de acord sau nu acceptați, fără limitări sau calificări,
            Condițiile de Utilizare ale acestui Site, vă rugăm să nu folosiți acest site.</span></p>
        <p class="c3"><span class="c2">2. PROPRIETATEA CONȚINUTULUI </span></p>
        <p class="c3"><span>Website-ul și tot ceea ce cuprinde acesta, incluzând fără limitare toate textele și
            imaginile (“conținut”) sunt în proprietatea și sub dreptul de autor (copyright) al </span><span
            class="c0">LUK-INSTALL PERFECT 24 SRL</span><span>, cu sediul in sat Clinceni Com. Clinceni, Str.
            Ciocarliei, Lotul 1, Tarlaua 33/1, Parcelele Nr. 195, Nr. 195/1/32 Si Nr. 195/1/33 Identificat Cadastral Sub
            Nr. 60606-C1-U1, Intabulat In C.F. Nr. 60606 A Clinceni, înregistrată la Registrul Comerțului sub
            J23/206/2018 | având cod fiscal RO36951457, cu toate drepturile rezervate cu excepția cazului în care nu
            este altfel specificat. Orice conținut care constă într-o marcă, logo, sau marca de serviciu reprezintă
            marcă înregistrată și neînregistrată a </span><span class="c0">Furnizorului</span><span class="c2">. Este
            strict interzisă utilizarea oricărui conținut, cu excepția celor prevăzute în condițiile de utilizare, fără
            permisiunea în scris a proprietarului “conținutului”. </span></p>
        <p class="c3"><span>De asemenea, vă informăm că </span><span class="c0">Furnizorul</span><span
            class="c2">&nbsp;își va asigura și impune în mod hotărât recunoașterea drepturilor de proprietate
            intelectuală în conformitate cu legile în vigoare, ajungând dacă este cazul la acționarea celor vinovați de
            încălcarea dreptului de proprietate intelectuală în judecată prin instanțele penale.</span></p>
        <p class="c3"><span class="c2">3. UTILIZAREA SITE-ULUI </span></p>
        <p class="c3"><span class="c0">Furnizorul</span><span class="c2">&nbsp;acordă permisiunea de a utiliza
            website-ul în următoarele condiții: </span></p>
        <ul class="c1 lst-kix_r7lk4qaydi9t-0 start">
          <li class="c3 c8"><span class="c2">Puteți descărca conținutul, însă doar pentru folosul personal și
              ne-comercial și cu condiția de a vă conforma cu informațiile referitoare la dreptul de autor (copyright)
              și alte drepturi de proprietate intelectuală cuprinse în conținut; </span></li>
          <li class="c3 c8"><span>Nu aveți permisiunea să modificați, să copiați (cu excepția celor mai sus menționate),
              să transmiteți, să expuneți, să refolosiți, să reproduceți, să publicați, să autorizați, să acordați o
              licență de folosire, să creați lucrări derivate din, sau să transferați, să vindeți sau să folosiți
              conținutul în alt mod, fără acordul în scris al </span><span class="c0">Furnizorului</span><span
              class="c2">; </span></li>
          <li class="c3 c8"><span>Este interzis să folosiți website-ul pentru a afișa sau transmite orice fel de
              material ce are caracter amenințător, fals, înșelător, abuziv, de hărțuire, licențios, calomniator,
              vulgar, obscen, scandalos, instigator, pornografic sau profanator, sau orice alt fel de material care
              poate constitui sau încuraja un comportament ce va putea da naștere unei infracțiuni, sau ar putea conduce
              la răspundere civila, sau ar încălca în alt mod legea. </span><span
              class="c0">Furnizorul</span><span>&nbsp;va coopera cu oricare dintre autoritățile desemnate să aplice
              legea și se va conforma cu orice sentință judecătorească prin care se cere sau se ordonă societății
            </span><span class="c0">Furnizorului</span><span class="c2">&nbsp;să dezvăluie identitatea oricărei persoane
              care ar afișa sau transmite orice fel de informație sau material de acest fel pe sau prin intermediul
              website-ului. </span></li>
        </ul>
        <p class="c3"><span>Sunteți de acord ca </span><span class="c0">Furnizorul</span><span class="c2">, la alegerea
            sa, să înceteze sau să întrerupă utilizarea de către dvs. a site-ului la nerespectarea Termenilor de
            Utilizare. În momentul suspendării sau încetării respective, dumneavoastră trebuie: </span></p>
        <p class="c3"><span class="c2">(a) să încetați utilizarea site-ului și </span></p>
        <p class="c3"><span class="c2">(b) să distrugeți orice copii făcute după orice parte a conținutului său. </span>
        </p>
        <p class="c3"><span class="c2">Nu veți considera Compania răspunzătoare pentru daunele, costurile, răspunderile,
            cheltuielile (inclusiv costurile avocaților) și sumele pentru înțelegerile legate de orice proces, pretenție
            sau acțiune depusă de către un terț împotriva Companiei drept rezultat al </span></p>
        <p class="c3"><span class="c2">(a) neglijentei, proastei reprezentări, erori sau omitere din partea dvs. sau
          </span></p>
        <p class="c3"><span class="c2">(b) încălcării din partea dvs. a Termenilor de Utilizare și a legilor sau
            regulamentelor de aplicare. </span></p>
        <p class="c3"><span class="c2">4. CONFIDENȚIALITATE </span></p>
        <p class="c3"><span class="c2">Orice date cu caracter personal precum: numele, adresa, numărul de telefon sau
            adresa de e-mail a dumneavoastră pe care o transmiteți pe website, prin poșta electronică sau prin altă
            modalitate vor fi folosite în conformitate cu Politica de Confidențialitate. </span></p>
        <p class="c3"><span class="c2">5. LIPSA GARANȚIILOR </span></p>
        <p class="c3"><span class="c2">Întreg conținutul acestui website poate fi modificat și vă este oferit ”ca atare”
            fără a se oferi nici o garanție de nici un fel, fie aceasta expresă sau implicită. </span></p>
        <p class="c3"><span class="c2">6. EXONERAREA DE RĂSPUNDERE </span></p>
        <p class="c3"><span>Utilizarea acestui website este în totalitate pe răspunderea dumneavoastră. </span><span
            class="c0">Furnizorul</span><span>, funcționarii, directorii, agenții sau orice altă parte implicată în
            conceperea, producerea sau oferirea website-ului nu sunt răspunzătoare pentru daune directe sau indirecte,
            de orice natură ce ar rezulta din sau în legătură cu utilizarea acestui website sau a conținutului său.
          </span><span class="c0">Furnizorul</span><span class="c2">&nbsp;nu își asumă nici o responsabilitate, și nu va
            fi răspunzătoare pentru nici o daună sau viruși care ar putea să vă infecteze computerul sau alte bunuri în
            urma accesării sau utilizării acestui website, sau descărcării oricărui material, informații, text, imagini
            video sau audio de pe acest website. </span></p>
        <p class="c3"><span class="c2">7. LINK-URI PE SITE-URILE UNEI TERȚE PĂRȚI </span></p>
        <p class="c3"><span>Site-ul poate conține link-uri către alte site-uri aflate în proprietatea sau operate de
            alte părți decât </span><span class="c0">Furnizorul</span><span>. Astfel de link-uri vă sunt furnizate
            pentru a le folosi numai dacă veți dori aceasta.</span><span class="c0">Furnizorul</span><span
            class="c2">&nbsp;nu controlează și nu este răspunzătoare pentru conținutul și condițiile de
            confidențialitate sau securitate și de funcționalitatea acestor site-uri. </span></p>
        <p class="c3"><span>Fără a se limita la cele mai sus menționate, </span><span class="c0">Furnizorul</span><span
            class="c2">&nbsp;își declină în mod special orice răspundere dacă aceste site-uri: </span></p>
        <ul class="c1 lst-kix_f3bgm2tfjt82-0 start">
          <li class="c6"><span class="c2">Încalcă drepturile de proprietate intelectuală ale unei terțe părți; </span>
          </li>
          <li class="c6"><span class="c2">Sunt inexacte, incomplete sau conțin informații înșelătoare; </span></li>
          <li class="c6"><span class="c2">Nu au caracter comercial sau nu răspund îndeplinirii unui anumit scop; </span>
          </li>
          <li class="c6"><span class="c2">Nu oferă o securitate adecvată; </span></li>
          <li class="c6"><span class="c2">Conțin viruși sau alte elemente cu caracter distructiv; </span></li>
          <li class="c3 c8"><span class="c2">Sunt licențioase sau calomnioase. </span></li>
        </ul>
        <p class="c3"><span class="c2">8. POLITICA FIȘIERELOR COOKIE </span></p>
        <p class="c3"><span class="c2">Un cookie este un fișier text care conține bucăți de date, colectate atunci când
            vizitați un website. Este proiectat pentru a ajuta website-urile să identifice activitatea trecută a
            utilizatorilor. Acest lucru poate include acțiunea de a urmări dacă ați făcut click pe link-uri speciale sau
            pagini, dacă v-ați conectat la profilul dumneavoastră, sau dacă ați citit anumite pagini într-o perioadă de
            mai multe luni. </span></p>
        <p class="c3"><span class="c2">Există diferite tipuri de cookie-uri și fără ele website-urile nu ar putea
            funcționa în modul în care v-ați obișnuit să le utilizați. </span></p>
        <p class="c3"><span class="c2">Website-ul www.curatenieperfecta.ro utilizează cookie-uri speciale pentru a va
            oferi cea mai bună experiență posibilă și sunt utilizate doar acele cookie-uri pe care le considerăm
            necesare și utile. </span></p>
        <p class="c3"><span class="c2">Va rugam sa consultati pagina dedicata Politicii de Cookie. Vă explicăm ce tipuri
            de cookie-uri sunt folosite pe acest website, astfel încât să puteți decide dacă sunteți mulțumit, dacă
            doriți să ștergeți cookie-urile existente sau chiar dezactiva folosirea cookie-urilor atunci când
            vizualizați website-ul nostru. </span></p>
        <p class="c3"><span class="c2">9. REVIZUIRI ALE ACESTOR TERMENE ȘI CONDIȚII </span></p>
        <p class="c3"><span class="c0">Furnizorul</span><span class="c2">&nbsp;poate, în orice moment și fără notificare
            prealabilă, să revizuiască aceste termene și condiții prin actualizarea acestora. Sunteți obligat să
            respectați oricare și toate astfel de revizuiri și de aceea va trebui să vizitați această pagină a site-ului
            nostru în mod periodic pentru a lua la cunoștință condițiile de utilizare actualizate. </span></p>
        <p class="c3"><span class="c0 c12">Legislație aplicabilă și Jurisdicție </span></p>
        <p class="c3"><span class="c2">Aceste termene și condiții și utilizarea acestui website sunt guvernate de legile
            din România. Instanțele competente din România vor avea jurisdicție exclusivă asupra oricăror și tuturor
            disputelor ce vor apărea din sau se vor referi la sau vor fi în legătură cu prevederile condițiilor de
            utilizare și/sau conținutul site-ului sau în cazul disputelor în care aceste termene și condiții și/sau
            acest website vor fi considerate fapte relevante pentru astfel de dispute.</span></p>
        <hr>
        <p class="c3 c9"><span class="c2"></span></p>
        <p class="c3"><span class="c2">Pentru orice tip de întrebare sau nelămurire, vă rugăm să ne contactați folosind
            următoarele date:</span></p>
        <p class="c3"><span class="c0">Email:</span><span>&nbsp;</span><span class="c10"><a class="c7"
              href="mailto:lukinstall24perfect@yahoo.com">lukinstall24perfect@yahoo.com</a></span><span
            class="c2">&nbsp;</span></p>
        <p class="c3"><span class="c0">Telefon</span><span class="c2">: 0743 697 295</span></p>
        <p class="c3"><span class="c0">Adresa:</span><span class="c2">&nbsp; sat Clinceni Com. Clinceni, Str.
            Ciocarliei, Lotul 1, Tarlaua 33/1, Parcelele Nr. 195, Nr. 195/1/32 Si Nr. 195/1/33 Identificat Cadastral Sub
            Nr. 60606-C1-U1, Intabulat In C.F. Nr. 60606 A Clinceni</span></p>
        <p class="c3 c9"><span class="c2"></span></p>
        <p class="c5"><span class="c2"></span></p>
        <p class="c5"><span class="c2"></span></p>
        <p class="c5"><span class="c2"></span></p>
        <p class="c5"><span class="c2"></span></p>
      </div>
    </div>
  </div>
</div>
