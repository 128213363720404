<!-- Featured Section -->
<section class="featured-section">
    <div class="auto-container">
      <div class="inner-container">
  
        <div class="clearfix">
  
          <!-- Featured Block -->
          <div class="featured-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box">
              <div class="content">
                <div class="icon-box">
                  <img src="/assets/images/icons/instalator.svg" alt="">
                </div>
                <h5><a href="#">Instalator Sanitar <br> Servicii profesionale</a></h5>
              </div>
            </div>
          </div>
  
          <!-- Featured Block -->
          <div class="featured-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box">
              <div class="content">
                <div class="icon-box">
                  <img src="/assets/images/icons/non-stop.svg" alt="">
                </div>
                <h5><a href="#">Instalator 24/7 <br> Interventii rapide</a></h5>
              </div>
            </div>
          </div>
  
          <!-- Featured Block -->
          <div class="featured-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box">
              <div class="content">
                <div class="icon-box">
                  <img src="/assets/images/icons/termice.svg" alt="">
                </div>
                <h5><a href="#">Instalatii termice <br> Preturi avantajoase</a></h5>
              </div>
            </div>
          </div>
  
        </div>
  
      </div>
    </div>
  </section>
  <!-- End Featured Section -->