	<!-- Page Title -->
	<section class="page-title" style="background-image: url(/assets/images/instalator-termic-bucuresti.jpg)">
	  <div class="auto-container">
	    <h1>Instalatii termice</h1>
	    <ul class="page-breadcrumb" style="display: none">
	      <li><a href="/">Acasa</a></li>
	      <li>Instalatii termice</li>
	    </ul>
	  </div>
	</section>
	<!-- End Page Title -->

	<!-- Sidebar Page Container -->
	<div class="sidebar-page-container left-sidebar">
	  <div class="auto-container">
	    <div class="row clearfix">
	      <!-- Sidebar Side -->
	      <div class="sidebar-side col-lg-4 col-md-12 col-sm-12">
	        <app-sidebar></app-sidebar>
	      </div>

	      <!-- Content Side -->
	      <div class="content-side col-lg-8 col-md-12 col-sm-12">
	        <div class="service-detail">
	          <div class="inner-box">
	            <div class="images-box">
	              <div class="row clearfix">
	                <!-- Image Column -->
	                <div class="image-column col-lg-6 col-md-6 col-sm-12">
	                  <div class="image">
	                    <img src="/assets/images/instalator-termic-bucuresti-calorifere.jpg" alt="" />
	                  </div>
	                </div>
	                <!-- Image Column -->
	                <div class="image-column col-lg-6 col-md-6 col-sm-12">
	                  <div class="image">
	                    <img src="/assets/images/instalator-termic-bucuresti-centrala-termica.jpg" alt="" />
	                  </div>
	                </div>
	              </div>
	            </div>
	            <div class="what-we-can-do">
	              <div class="row clearfix">
	                <div class="col-lg-6 col-md-6 col-sm-12">
	                  <p>CE PUTEM FACE?</p>
	                </div>
	                <div class="col-lg-6 col-md-6 col-sm-12">
	                  <ul>
	                    <li>Montaj calorifere </li>
	                    <li>Montaj centrale termice</li>
	                    <li>Realizare traseu incalzire prin pardoseala</li>
	                    <li>Reparatii calorifere</li>
	                    <li>Inlocuire robineti calorifere</li>
	                    <li>Echilibrare instalatie termica</li>
	                    <li>Montaj pompe de caldura</li>
	                  </ul>
	                </div>
	              </div>
	            </div>
	            <div class="what-we-can-do-after">
	              <ul>
	                <li>Intervenție în maxim 30 minute</li>
	                <li>Instalator sanitar Bucuresti in toate sectoarele si pe toata raza Ilfov</li>
	                <li>Servicii de URGENTA, 24/7</li>
	              </ul>
	            </div>
	            <div class="lower-content">
	              <h2>Servicii Instalator Termic - Estimare Gratuita</h2>
	              <p>Luk Install ofera o gama variata de servicii pentru instalatiile termice, asigurand un instalator
	                autorizat atunci cand primim sesizari sau solicitari pentru realizarea instalatiilor termice. Montam
	                orice tip de calorifer</p>

	              <div class="two-column">
	                <div class="row clearfix">
	                  <div class="column col-lg-6 col-md-6 col-sm-12">
	                    <ul class="list-style-two">
	                      <li>Montam orice tip de calorifer</li>
	                      <li>Montam orice tip de robineti</li>
	                      <li>Montam orice tip de centrala termica</li>
	                    </ul>
	                    <p>Oferta noastra de servicii legate de instalatiile de incalzire, este adresata atat nevoilor
	                      utilizatorilor casnici cat si companiilor sau altor institutii.</p>
	                  </div>
	                  <div class="column col-lg-6 col-md-6 col-sm-12">
	                    <img src="/assets/images/instalatii-termice.jpg" alt="" />
	                  </div>
	                </div>
	              </div>

	              <div class="featured-blocks">
	                <div class="clearfix">
	                  <h2>Instalatii Termice in Bucuresti si Ilfov</h2>
	                  <p>Avem o echipa de instalatori termici autorizati, specializati in asigurarea serviciilor de montaj,
	                    intretinere si reparatii centrale termice. Centralele termice pot fi cu incalzire pe gaze, lemne,
	                    paleti sau de tip electric. Avem instalatori autorizati specializati in realizarea instalatiilor
	                    termice cu performanta ridicata astfel incat proiectul unei instalatii bine definite sa fie unul cu
	                    randament ridicat.</p>
	                  <!-- Featured Block -->
	                  <div class="featured-block col-lg-6 col-md-6 col-sm-12">
	                    <div class="inner-box">
	                      <div class="content">
	                        <div class="icon-box">
	                          <img src="/assets/images/fast.svg" alt="">
	                        </div>
	                        <h5><a>Deplasare Rapida <br>
                                Bucuresti si Ilfov
                                <br><br>
	                          </a></h5>
	                      </div>
	                    </div>
	                  </div>

	                  <!-- Featured Block -->
	                  <div class="featured-block col-lg-6 col-md-6 col-sm-12">
	                    <div class="inner-box">
	                      <div class="content">
	                        <div class="icon-box">
	                          <img src="/assets/images/urgent.svg" alt="">
	                        </div>
	                        <h5><a>Instalator 24/7 <br>
	                            Urgenta ta este prioritara
	                          </a></h5>
	                      </div>
	                    </div>
	                  </div>

	                  <!-- Featured Block -->
	                  <div class="featured-block col-lg-6 col-md-6 col-sm-12">
	                    <div class="inner-box">
	                      <div class="content">
	                        <div class="icon-box">
	                          <img src="/assets/images/termic.svg" alt="">
	                        </div>
	                        <h5><a>Montam Toate Tipurile <br>
	                            Centrale termice si calorifere
	                          </a></h5>
	                      </div>
	                    </div>
	                  </div>

	                  <!-- Featured Block -->
	                  <div class="featured-block col-lg-6 col-md-6 col-sm-12">
	                    <div class="inner-box">
	                      <div class="content">
	                        <div class="icon-box">

	                          <img src="/assets/images/guarantee.svg" alt="">
	                        </div>
	                        <h5><a>Garantia Lucrarii <br>
                                Intre 6 luni si 5 ani
                                <br><br>
	                          </a></h5>
	                      </div>
	                    </div>
	                  </div>
                    </div>
                   
                  </div>
                  
                  <p>Utilizam doar materiale certificate, de cea mai buna calitate astfel incat sa asiguram o garantie
                    cat mai mare a instalatiilor termice realizate de instalatorii Luk Install in toate sectoarele din
                    Bucuresti si din Ilfov.</p>

	              <div class="btns-box clearfix">
	                <a href="/contact" class="theme-btn btn-style-one"><span class="txt"> Cere oferta</span></a>
	                <a href="tel:+40743697295" class="talk">Suna acum un instalator <span>0743-697-295</span></a>
	              </div>

	            </div>

	          </div>
	        </div>
	      </div>

	    </div>
	  </div>
	</div>
	<!-- End Sidebar Page Container -->
