import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instalator-sanitar',
  templateUrl: './instalator-sanitar.component.html',
  styleUrls: ['./instalator-sanitar.component.scss']
})
export class InstalatorSanitarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
