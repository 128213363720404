<div class="section padding-bottom-extra">
  <div class="container flex-horizontal">
    <div class="col lg-12 md-12 no-margin-bottom-lg">
      <div class="sidebar-page-container">

        <h1 class="c8" id="h.nshf36iqb0bb"><span class="c36">POLITICA DE COOKIE-URI</span></h1>
        <p class="c21"><span class="c4">1. &nbsp; INTRODUCERE</span></p>
        <p class="c23"><span class="c4">Termenii şi condiţiile de utilizare a paginii de internet disponibilă la
            adresa www.lukinstall.ro (denumit în continuare "Site")</span></p>
        <p class="c1"><span class="c4">Această politică privind utilizarea cookie-urilor este concepută pentru a vă
            informa în legătură cu practicile noastre de colectarea a informațiilor dvs. atunci când vizitati site-ul
            nostru, prin intermediul cookie-urilor și a altor tehnologii de urmărire, însă cu respectarea dreptului
            dvs. la protecția datelor cu caracter personal.</span></p>
        <p class="c1"><span class="c9 c15">Cookie-urile noastre nu rețin date cu caracter personal, cum ar fi numele
            sau detaliile bancare. </span></p>
        <p class="c1"><span class="c4">Confidențialitatea informatiilor este o responsabilitate permanentă. Ne asumăm
            că vom actualiza periodic acest document de informare pe măsură ce vom întreprinde noi practici privind
            datele cu caracter personal sau vom adopta noi politici de confidențialitate. În ceea ce privește
            prelucrarea datelor bazate pe consimțământ, vă vom notifica orice modificare și vom solicita
            consimțământul dvs. suplimentar.</span></p>
        <p class="c1"><span class="c15 c9">Nu vom solicita ori primi informații, de orice natura, de la copiii sub 16
            ani.</span></p>
        <p class="c1"><span class="c4">Informațiile de mai jos aduc la cunoștința dvs. mai multe detalii despre
            plasarea, utilizarea si administrarea cookie-urilor utilizate de site-ul www.lukinstall.ro. De asemenea,
            sunt prezente și câteva linkuri utile legate de acest subiect.</span></p>
        <p class="c1"><span>Site-ul www.lukinstall.ro este operat de </span><span class="c9">LUK-INSTALL PERFECT 24
            SRL</span><span>, cu sediul in sat Clinceni Com. Clinceni, Str. Ciocarliei, Lotul 1, Tarlaua 33/1,
            Parcelele Nr. 195, Nr. 195/1/32 Si Nr. 195/1/33 Identificat Cadastral Sub Nr. 60606-C1-U1, Intabulat In
            C.F. Nr. 60606 A Clinceni, înregistrată la Registrul Comerțului sub J23/206/2018 | având cod fiscal
            RO36951457,</span><span>&nbsp;E-mail: &nbsp;</span><span class="c32"><a class="c7"
              href="mailto:lukinstall24perfect@yahoo.com">lukinstall24perfect@yahoo.com</a></span><span
            class="c4">&nbsp; 0743 697 29</span></p>
        <p class="c1"><span class="c4">Utilizăm cookie-uri, atât proprii, cât și de la terți, pentru a furniza
            vizitatorilor o experiență mai buna de navigare și servicii adaptate nevoilor și interesului vizitatorilor
            și clienților săi. </span></p>
        <hr>
        <p class="c1 c2"><span class="c4"></span></p>
        <p class="c1"><span class="c4">2. &nbsp;CONTEXTUL LEGISLATIV</span></p>
        <p class="c1"><span class="c4">Conform cerințelor Directivei (UE) 2002/58/EC („Directiva E-Privacy”) și a
            Regulamentului (UE) 2016/679 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor
            cu caracter personal și privind libera circulație a acestor date, avem obligația de a administra în
            condiții de siguranță și numai pentru scopurile specificate, datele personale pe care dvs. ni le furnizați
            prin utilizarea site-ului nostru în acord cu prevederile Regulamentului GDPR și a Directivei
            E-Privacy.</span></p>
        <p class="c1"><span class="c4">În special, Regulamentul GDPR impune oferirea posibilitatății ca utilizatorul
            să-și exprime sau, dupa caz, să-și retragă oricând consimțământul pentru prelucrarea datelor sale cu
            caracter personal, să rectifice conținutul datelor cu caracter personal sau să solicite transferul datelor
            cu caracter personal către un alt operator, iar, în al doilea rând, să informăm utilizatorul despre
            conținutul cookie-urilor utilizate de site-ul nostru, ce categorii de date personale ale utilizatorului
            sunt prelucrate de cookie-urile noastre, ce facem cu informațiile obținute de cookie-uri și unde stocăm
            respectivele informații, oferirea unui nivel de securitate ridicat asupra informațiilor colectate de
            cookie-uri, posibilitatea utilizatorului de a bloca oricând rularea oricărui sau a tuturor cookie-urilor
            de pe site-ul nostru, precum și stergerea informațiilor colectate de cookie-uri.</span></p>
        <p class="c1"><span class="c4">Pentru mai multe detalii privind conținutul acestor norme juridice, vă rugăm să
            accesați următoarele link-uri:</span></p>
        <ul class="c13 lst-kix_48gqio77fzob-0 start">
          <li class="c6"><span><a class="c7"
                href="https://www.google.com/url?q=https://eur-lex.europa.eu/legal-content/RO/TXT/?uri%3Dcelex%253A32002L0058&amp;sa=D&amp;ust=1606237553985000&amp;usg=AOvVaw25yvQHwpPfA-sLsjgP_yPf">https://eur-lex.europa.eu/legal-content/RO/TXT/?uri=celex%3A32002L0058</a></span>
          </li>
          <li class="c1 c20"><span><a class="c7"
                href="https://www.google.com/url?q=https://eur-lex.europa.eu/legal-content/RO/TXT/?uri%3DCELEX%253A32016R0679&amp;sa=D&amp;ust=1606237553986000&amp;usg=AOvVaw2rW5oluGkVmhH_RxwE9l7L">https://eur-lex.europa.eu/legal-content/RO/TXT/?uri=CELEX%3A32016R0679</a></span>
          </li>
        </ul>
        <hr>
        <p class="c1 c22 c2"><span class="c4"></span></p>
        <p class="c16"><span class="c4">3. DREPTURILE DUMNEAVOASTRA</span></p>
        <p class="c1"><span class="c4">Regulamentul GDPR oferă anumite drepturi persoanelor vizate. </span></p>
        <ul class="c13 lst-kix_39jsau88sb3-0 start">
          <li class="c1 c18"><span class="c4">Aveți dreptul de a accesa și de a rectifica (corecta) înregistrarea
              datelor dvs. personale prelucrate de noi dacă acestea sunt inexacte. </span></li>
          <li class="c1 c18"><span class="c4">Ne puteți solicita să ștergem aceste date sau să încetam prelucrarea
              acestora, sub rezerva anumitor excepții. </span></li>
          <li class="c1 c18"><span class="c4">Ne puteți solicita încetarea utilizării datelor dvs. în scopuri de
              marketing direct. </span></li>
          <li class="c1 c18"><span class="c4">Aveți dreptul să depuneți o plângere la autoritatea de protecție a
              datelor dacă aveți îngrijorări cu privire la modul în care prelucrăm datele dvs. personale. Când este
              fezabil din punct de vedere tehnic, vom putea – la cererea dvs. – să vă furnizăm datele personale sau să
              le transmitem direct unui alt operator. Dacă accesul nu poate fi furnizat într-un interval de timp
              rezonabil, vă vom furniza o dată la care informațiile vor fi transmise. </span></li>
          <li class="c1 c18"><span>Puteți solicita, de asemenea, informații suplimentare despre: scopul prelucrării,
              categoriile de date cu caracter personal vizate, care a fost sursa informațiilor și cât timp vor fi
              stocate. Dacă doriți să executați oricare dintre aceste drepturi, vă rugăm să ne contactati la adresa
            </span><span class="c32"><a class="c7"
                href="mailto:lukinstall24perfect@yahoo.com">lukinstall24perfect@yahoo.com</a></span><span class="c4">,
              oricând.</span></li>
        </ul>
        <p class="c1"><span class="c15 c9">AVERTIZARE! Vă rugăm să vă exersați în mod rațional drepturile și să
            rețineti că abuzul de drepturi vă poate atrage răspunderea.</span></p>
        <p class="c1"><span class="c4">Datele de contact ale Autorității Naționale de Protectie a Datelor cu caracter
            personal sunt următoarele:</span></p>
        <p class="c1"><span class="c9 c35 c11 c37">Autoritatea Nationala de Supraveghere a Prelucrarii Datelor cu
            Caracter Personal</span></p>
        <ul class="c13 lst-kix_hpxrh123qdqz-0 start">
          <li class="c6"><span class="c14 c11">Adresa: Bucuresti, b-dul G-ral. Gheorghe Magheru nr. 28-30, sector 1,
              cod postal 010336</span></li>
          <li class="c6"><span class="c11">Pagina de internet:</span><span class="c11"><a class="c7"
                href="https://www.google.com/url?q=http://www.dataprotection.ro/&amp;sa=D&amp;ust=1606237553987000&amp;usg=AOvVaw3B_LPOAYUaZiG0p6hHDQ_t">http://www.dataprotection.ro</a></span>
          </li>
          <li class="c6"><span class="c11 c14">Adresa de e-mail: anspdcp@dataprotection.ro</span></li>
          <li class="c6"><span class="c14 c11">Telefon:+40.318.059.211; +40.318.059.212</span></li>
          <li class="c1 c20"><span class="c14 c11">Fax: +40.318.059.602</span></li>
        </ul>
        <hr>
        <p class="c1 c22 c2"><span class="c14 c11"></span></p>
        <p class="c16"><span class="c4">4. CONSIMȚĂMÂNTUL DVS. PENTRU UTILIZAREA COOKIE-URILOR</span></p>
        <p class="c1"><span class="c4">Odată ce ați accesat site-ul nostru veți fi întâmpinat de un mesaj prin care vi
            se va cere consimțământul în acord cu dispozițiile legale aplicabile cu privire la categoriile de
            cookie-uri pe care veți fi sau nu de acord să le folosim pe durata vizitei dvs. pe site-ul nostru. De
            asemenea, este important de reținut că prin consimțământul dat în sensul utilizarii cookie-ului/urilor
            respectiv/e veți fi de acord și cu stocarea în condiții de siguranță a informațiilor colectate de
            cookie-ul/-urile cu care ați fost de acord.</span></p>
        <p class="c1"><span class="c4">Până la momentul la care procesul de accept/refuz pentru utilizarea
            cookie-urilor nu este finalizat, niciun cookie nu va fi utilizat de site-ul nostru, cu excepția
            cookie-urilor în lipsa cărora site-ul nu poate funcționa.</span></p>
        <p class="c1"><span class="c4">Precizăm că oricând veți avea posibilitatea să blocați rularea cookie-urilor,
            dar și posibilitatea de a șterge informațiile colectate de cookie-uri.</span></p>
        <hr>
        <p class="c1 c2"><span class="c4"></span></p>
        <p class="c17"><span class="c4">5. CE ESTE UN “COOKIE”?</span></p>
        <h1 class="c26 c27" id="h.bwbremk5vlu8"><span class="c25 c31">Cookie-ul este un fişier de mici dimensiuni,
            format din litere şi numere, care va fi stocat pe computerul, terminalul mobil sau alte echipamente ale
            unui utilizator de pe care se accesează Internetul. Cookie-ul este instalat prin solicitarea emisă de
            către un web-server unui browser (ex: Internet Explorer, Chrome) şi este complet "pasiv" (nu conţine
            programe software, viruşi sau spyware şi nu poate accesa informaţiile de pe hard disk-ul
            utilizatorului).</span></h1>
        <p class="c26"><span class="c4">Un cookie este format din 2 părți: numele și conținutul sau valoarea
            cookie-ului. Mai mult, durata de existență a unui cookie este determinată, iar tehnic doar web-serverul
            care a trimis cookie-ul îl poate accesa din nou în momentul în care un utilizator se întoarce pe
            website-ul asociat web-serverului respectiv.</span></p>
        <p class="c17"><span class="c15 c9">Cookie-urile în sine nu solicită informații cu caracter personal pentru a
            putea fi utilizate și, în cele mai multe cazuri, nu identifică personal utilizatorii de internet.</span>
        </p>
        <p class="c1"><span class="c4">Există 2 categorii mari de cookie-uri:</span></p>
        <ol class="c13 lst-kix_etbdap1boa5e-0 start" start="1">
          <li class="c6"><span class="c9">Cookie-uri de sesiune</span><span class="c4">&nbsp;– acestea sunt stocate
              temporar în dosarul de cookie-uri al browserului web pentru ca acesta să le memoreze până când
              utilizatorul iese de pe site-ul respectiv sau închide fereastra browserului.</span></li>
          <li class="c1 c20"><span class="c9">Cookie-uri fixe / persistente</span><span class="c4">&nbsp;– acestea
              sunt stocate pe hard drive-ul unui computer sau echipament pentru o durata configurata de website-ul
              care l-a plasat. Acest tip de cookie-uri pot fi șterse de utilizator în orice moment prin intermediul
              setărilor browserului.</span></li>
        </ol>
        <hr>
        <p class="c1 c2 c22"><span class="c4"></span></p>
        <p class="c17"><span class="c4">6. CARE SUNT AVANTAJELE COOKIE-URILOR?</span></p>
        <p class="c1"><span class="c4">Un cookie conține informații care fac legătura între un web-browser (browser-ul
            pe care îl folosiți dvs., spre exemplu Internet Explorer, Google Chrome, Safari, Mozilla Firefox, etc.) și
            un web-server anume, mai precis serverul care găzduiește site-ul nostru. Dacă un browser accesează acel
            web-server din nou, acesta poate citi informația deja stocată și reacționa în consecință. Cookie-urile
            asigură utilizatorilor o experiență plăcuta de navigare și susțin eforturile multor websiteuri pentru a
            oferi servicii confortabile utilizatorillor: ex. Preferințele în materie de confidențialitate online,
            opțiunile privind limba site-ului, coșuri de cumpărături sau afișarea de publicitate relevantă.</span></p>
        <h1 class="c27 c26" id="h.k15b5o7hbn68"><span class="c0">De asemenea, sunt utilizate în pregătirea unor
            statistici anonime agregate care ne ajută să înţelegem cum un utilizator beneficiază de paginile noastre
            web, permiţându-ne îmbunătăţirea structurii şi conţinutului lor, excluzând indentificarea personală a
            utilizatorului.</span></h1>
        <hr>
        <h1 class="c27 c26 c30" id="h.bllfql60l7k0"><span class="c0"></span></h1>
        <p class="c2 c29"><span class="c4"></span></p>
        <p class="c29"><span class="c4">7. CUM FOLOSIM NOI COOKIE-URILE PE ACEST SITE</span></p>
        <p class="c1"><span class="c4">Am clasificat cookie-urile noastre în următoarele categorii, pentru a vă fi mai
            ușor să întelegeți de ce avem nevoie de ele.</span></p>
        <h1 class="c10" id="h.bwbremk5vlu8-1"><span class="c0">O vizită pe acest site poate plasa cookie-uri în
            scopuri de:</span></h1>
        <ul class="c13 lst-kix_d8v56o9l508q-0 start">
          <li class="c27 c26 c28"><span class="c9 c25">Cookie-uri de performanţă a site-ului</span><span
              class="c5">&nbsp;- </span><span class="c4">sunt utilizate pentru a analiza modul în care funcționează
              site-ul nostru și cum îl putem imbunătăți, de exemplu, care sunt paginile vizualizate cel mai des și
              dacă întâmpinați mesaje de eroare. Acestea nu colecteaza informații care vă pot identifica, toate datele
              fiind anonime. Utilizând site-ul nostru vă exprimați acordul privind utilizarea de cookie-uri de
              “performanță”. Dacă le blocați, nu putem prezice cum va evolua site-ul pentru dvs.</span></li>
          <li class="c19">
            <h1 id="h.bwbremk5vlu8-2" style="display:inline"><span class="c5 c9">Cookie-uri de analiză a vizitatorilor
                (Google Analytics) </span><span class="c5">- </span><span class="c35">acestea ajută la îmbunătățirea
                experienței dvs. acestea pot înregistra modificările pe care le-ați făcut pe site. Informațiile pe
                care le colectează pot fi făcute anonime. Acestea se referă strict la site-ul nostru și nu pot urmări
                alte site-uri pe care le vizitați. Aveți posibilitatea să blocați sau să permiteți utilizarea acestor
                cookie-uri, dar blocarea lor poate însemna că nu vom mai putea furniza anumite servicii. Este de
                asemenea posibil ca blocarea lor să ne impiedice să memorăm că nu ați dorit anumite informații.</span>
            </h1>
          </li>
          <li class="c19">
            <h1 id="h.8vx5xhczriu2" style="display:inline"><span class="c5 c9">Cookie-uri de înregistrare</span><span
                class="c5">&nbsp;- acestea ajută la memorarea datelor dvs. de acees în cadrul propriului cont de
                utilizator. Astfel, dispozitivul de pe care accesați contul de utilizator vă va permite logarea fără
                să fiți nevoi să reintroduceți aceste date. </span><span class="c35">Aveți posibilitatea să blocați
                sau să permiteți utilizarea acestor cookie-uri, dar blocarea lor înseamnă că va trebui să
                reintroduceți userul și parola de fiecare dată când vă logați în propriul cont de utilizator.</span>
            </h1>
          </li>
          <li class="c19"><span class="c5 c9">Cookie-uri pentru publicitate (Google Ads, Google Display, Facebook Ads)
            </span><span class="c4">acestea sunt utilizate pentru a face schimb de anumite informații cu terțe părți
              cu care facem reclamă, pentru a ști cum ați ajuns pe site-ul nostru. De asemenea, putem utiliza
              cookie-uri pentru a identifica părțile site-ului de care sunteți interesat. Utilizăm apoi aceste
              informații pentru a vă arăta reclame și pagini care credem că vă pot interesa, pentru a individualiza
              modul în care comunicăm cu dvs. sau pentru a individualiza conținutul comunicărilor pe care vi le
              trimitem.</span></li>
        </ul>
        <p class="c3"><span class="c4">Dacă preferați, puteți opta pentru a renunța la acestea. Individualizarea
            conținutului înseamnă că acesta include informații care reflectă interesul pe care l-ați arătat față de
            conținutul paginilor noastre sau oferte ori promoții care credem că v-ar putea interesa, și pentru a
            îmbunătăți modul în care răspundem la necesitățile dvs.</span></p>
        <hr>
        <p class="c3 c2"><span class="c4"></span></p>
        <p class="c16"><span class="c4">9. CE SUNT COOKIE-URILE PLASATE DE TERȚI?</span></p>
        <p class="c1"><span class="c4">Cookie-uri plasate de terți („third party cookies”) sunt plasate de un alt
            website decât cel pe care îl vizitează utilizatorul la momentul respectiv și ar putea fi folosite în mod
            anonim pentru a memora istoricul de navigare al unui utilizator, astfel încât acestuia să-i fie livrată
            publicitate cât mai relevantă. Acest tip de cookie-uri pot fi blocate de un utilizator prin intermediul
            setărilor browser-ului.</span></p>
        <p class="c1"><span class="c4">Precizăm că nu avem control și nu suntem responsabili pentru politicile și
            practicile de confidențialitate ale unor terțe părți, însă terțele părți se obligă să respecte legea in
            vigoare și politicile de confidențialitate ale deținatorului acestui site.</span></p>
        <hr>
        <p class="c1 c2"><span class="c4"></span></p>
        <p class="c1"><span class="c4">10. CARE ESTE DURATA DE VIAȚĂ A UNUI COOKIE?</span></p>
        <p class="c1"><span class="c4">Cookie-urile sunt administrate de web-servere. Durata de viață a unui cookie
            poate varia semnificativ, depinzând de scopul pentru care este plasat. Unele cookie-uri sunt folosite
            exclusiv pentru o singură sesiune (cookie-urile de sesiune) și nu mai sunt reținute odată ce utilizatorul
            părăsește website-ul și unele cookie-uri sunt reținute și refolosite de fiecare dată când utilizatorul
            revine pe acel website (cookie-urile persistente). Cu toate acestea, cookie-urile pot fi șterse de un
            utilizator în orice moment.</span></p>
        <hr>
        <p class="c1 c2"><span class="c4"></span></p>
        <p class="c16"><span class="c4">11. CUM PUTEȚI OPRI COOKIE-URILE?</span></p>
        <p class="c1"><span class="c4">Dezactivarea și refuzul de a primi cookie-uri pot face anumite site-uri
            impracticabile sau dificil de vizitat și folosit. De asemenea, refuzul de a accepta cookie-uri nu înseamnă
            că nu veți mai primi/vedea publicitate online.</span></p>
        <p class="c1"><span class="c4">Este posibilă setarea din browser pentru ca aceste cookie-uri să nu mai fie
            acceptate sau puteți seta browserul să accepte cookie-uri de la un site anume.</span></p>
        <p class="c1"><span class="c4">Toate browserele moderne oferă posibilitatea de a schimba setările
            cookie-urilor. Aceste setări se găsesc de regula în “opțiuni” sau în meniul de “preferințe” al browserului
            dvs.</span></p>
        <p class="c1"><span class="c4">Pentru a înțelege aceste setări, următoarele linkuri pot fi folositoare, altfel
            puteți folosi opțiunea “ajutor” a browserului pentru mai multe detalii.</span></p>
        <ul class="c13 lst-kix_z59brvl7r1vu-0 start">
          <li class="c6"><span><a class="c7"
                href="https://www.google.com/url?q=https://support.microsoft.com/en-us/kb/196955&amp;sa=D&amp;ust=1606237553992000&amp;usg=AOvVaw0ky8b44_gOcTrnnTVzAVIC">Cookie
                settings in Internet Explorer</a></span></li>
          <li class="c6"><span><a class="c7"
                href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?hl%3Den&amp;sa=D&amp;ust=1606237553993000&amp;usg=AOvVaw39ev63-iyUYdadUl7cdPBo">Cookie
                settings in Chrome</a></span></li>
          <li class="c6"><span><a class="c7"
                href="https://www.google.com/url?q=https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale%3Den-US%26redirectslug%3DCookies&amp;sa=D&amp;ust=1606237553993000&amp;usg=AOvVaw1V7AvXTwZmUNwlcv02IwIc">Cookie
                settings in Firefox</a></span></li>
          <li class="c1 c20"><span><a class="c7"
                href="https://www.google.com/url?q=https://support.apple.com/kb/PH5042?locale%3Den_US&amp;sa=D&amp;ust=1606237553993000&amp;usg=AOvVaw34K-WIkS8vYAJpvgDP-tNJ">Cookie
                settings in Safari</a></span></li>
        </ul>
        <p class="c1"><span class="c4">Pentru setările cookie-urilor generate de terți, puteți consulta și
            site-ul:</span></p>
        <ul class="c13 lst-kix_hyupy0mfizma-0 start">
          <li class="c1 c20"><span><a class="c7"
                href="https://www.google.com/url?q=http://www.youronlinechoices.com/ro/&amp;sa=D&amp;ust=1606237553994000&amp;usg=AOvVaw1aucACVtdn0OvTT_V0C57I">http://www.youronlinechoices.com/ro/</a></span>
          </li>
        </ul>
        <p class="c1"><span class="c15 c9">Linkuri utile:</span></p>
        <p class="c1"><span class="c4">Dacă doriți să aflați mai multe informații despre cookie-uri și la ce sunt
            utilizate, recomandăm următoarele linkuri:</span></p>
        <ul class="c13 lst-kix_xv50bs5odh5i-0 start">
          <li class="c6"><span><a class="c7"
                href="https://www.google.com/url?q=http://www.allaboutcookies.org/&amp;sa=D&amp;ust=1606237553994000&amp;usg=AOvVaw0-OmF3BJqvVT9RhtkTnImv">All
                About Cookies</a></span></li>
          <li class="c1 c20"><span><a class="c7"
                href="https://www.google.com/url?q=https://www.microsoft.com/info/cookies.mspx&amp;sa=D&amp;ust=1606237553995000&amp;usg=AOvVaw3c5pf2oRe_CfQ-WMqaLV3S">Microsoft
                Cookies guide</a></span></li>
        </ul>
        <p class="c1"><span class="c4">Pentru a opta să nu mai fiți urmărit de Google Analytics te rugăm să
            vizitezi:</span></p>
        <ul class="c13 lst-kix_s0sd2hp1w1mm-0 start">
          <li class="c1 c20"><span><a class="c7"
                href="https://www.google.com/url?q=http://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;ust=1606237553995000&amp;usg=AOvVaw35cM7bsMgx89RTbUdzF2x8">http://tools.google.com/dlpage/gaoptout</a></span><span
              class="c4">.</span></li>
        </ul>
        <hr>
        <p class="c1 c22 c2"><span class="c4"></span></p>
        <p class="c1"><span class="c4">12.ASPECTE DE SECURITATE LEGATE DE COOKIE-URI</span></p>
        <p class="c1"><span class="c4">Cookieurile NU sunt viruși! Ele folosesc formate tip plain text. Nu sunt
            alcătuite din bucăți de cod așa că nu pot fi executate nici nu pot auto-rula. In consecință, nu se pot
            duplica sau replica pe alte rețele pentru a se rula sau replica din nou. Deoarece nu pot îndeplini aceste
            funcții, nu pot fi considerate viruși.</span></p>
        <p class="c1"><span class="c4">Cookie-urile pot fi totuși folosite pentru scopuri negative. Deoarece stochează
            informații despre preferințele și istoricul de navigare al utilizatorilor, atât pe un anume site cât și pe
            mai multe alte siteuri, cookie-urile pot fi folosite ca o formă de Spyware. Multe produse anti-spyware
            sunt conștiente de acest fapt și în mod constant marchează cookie-urile pentru a fi șterse în cadrul
            procedurilor de ștergere/scanare anti-virus/anti-spyware.</span></p>
        <p class="c1"><span class="c4">In general, browserele au integrate setări de confidențialitate care furnizează
            diferite nivele de acceptare a cookie-urilor, perioada de valabilitate și ștergere automată după ce
            utilizatorul a vizitat un anumit site.</span></p>
        <p class="c1"><span class="c4">Deoarece protecția identității este foarte valoroasă și reprezintă dreptul
            fiecărui utilizator de internet, este indicat să se știe ce eventuale probleme pot crea cookie-urile.
            Pentru că prin intermediul lor se transmit în mod constant în ambele sensuri informații între browser și
            website, dacă un atacator sau persoană neautorizată intervine în parcursul de transmitere a datelor,
            informațiile conținute de cookie pot fi interceptate. Deși foarte rar, acest lucru se poate întâmpla dacă
            browserul se conectează la server folosind o rețea necriptată (ex: o retea WiFi nesecurizată).</span></p>
        <hr>
        <p class="c1 c2"><span class="c4"></span></p>
        <p class="c16"><span class="c4">13.MODIFICĂRI ALE POLITICII PRIVIND COOKIE-URILE</span></p>
        <p class="c1"><span class="c4">Este posibil să actualizăm din când în când acest document de informare prin
            publicarea unei noi versiuni pe acest site web.</span></p>
        <p class="c1"><span class="c4">Având în vedere că legile privind protecția datelor cu caracter personal,
            interpretarile organelor de stat, recomandările autorităților de supraveghere se schimbă și se
            imbunătățesc periodic, se așteaptă ca această notificare privind confidențialitatea și condițiile de
            utilizare să se schimbe și ea. Ne rezervăm dreptul de a modifica în orice moment, din orice motiv și fără
            notificare acest document de informare. Orice modificare a politicii noastre de confidențialitate va fi
            afișată pe această pagină, astfel încât să fiți conștienți de politicile noastre. Dispozițiile conținute
            în prezentul document înlocuiesc toate anunțurile sau declarațiile anterioare privind practicile noastre
            de confidențialitate.</span></p>
        <hr>
        <p class="c1 c2"><span class="c4"></span></p>
        <p class="c16"><span class="c4">14.SFATURI PENTRU O NAVIGARE SIGURĂ ȘI RESPONSABILĂ, BAZATĂ PE
            COOKIE-URI</span></p>
        <p class="c1"><span class="c4">Datorită flexibilității lor și a faptului că majoritatea dintre cele mai
            vizitate site-uri și cele mai mari folosesc cookie-uri, acestea sunt aproape inevitabile. Dezactivarea
            cookie-urilor nu va permite accesul utilizatorului pe site-urile cele mai răspândite și utilizate printre
            care Youtube, Gmail, Yahoo și altele.</span></p>
        <p class="c1"><span class="c4">Iată câteva sfaturi care vă pot asigura însă că navigați fără griji cu ajutorul
            cookie-urilor:</span></p>
        <ul class="c13 lst-kix_stqi6m7xq8u-0 start">
          <li class="c6"><span class="c4">Particularizați-vă setările browserului în ceea ce privește cookie-urile
              pentru a reflecta un nivel confortabil pentru dvs. al securității utilizării cookie-urilor.</span></li>
          <li class="c12 c33"><span class="c4">Dacă nu vă deranjează cookie-urile și sunteți singura persoană care
              utilizează computerul, puteți seta termene lungi de expirare pentru stocarea istoricului de navigare și
              al datelor personale de acces.</span></li>
          <li class="c12 c33"><span class="c4">Dacă împărțiți accesul la calculator, puteți lua în considerare setarea
              browserului pentru a șterge datele individuale de navigare de fiecare dată când închideti browserul.
              Aceasta este o varianta de a accesa site-urile care plasează cookieuri și de a șterge orice informație
              de vizitare la închiderea sesiunii de navigare.</span></li>
          <li class="c12 c33"><span class="c4">Instalați-vă și updatați-vă constant aplicații antispyware.</span></li>
        </ul>
        <p class="c12 c2 c24"><span class="c4"></span></p>
        <p class="c12"><span class="c4">Multe dintre aplicațiile de detectare și prevenire a spyware-ului includ
            detectarea atacurilor pe site-uri. Astfel, împiedica browserul de la a accesa website-uri care ar putea să
            exploateze vulnerabilitățile browserului sau să descarce software periculos. Asigurați-vă că aveți
            browserul mereu updatat. Multe dintre atacurile bazate pe cookies se realizează exploatând punctele slabe
            ale versiunilor vechi ale browserelor.</span></p>
        <p class="c12"><span class="c4">Cookie-urile sunt pretutindeni și nu pot fi evitate dacă doriți să vă bucurați
            de acces pe cele mai bune și cele mai mari site-uri de pe Internet – locale sau internaționale. Cu o
            întelegere clară a modului lor de operare și a beneficiilor pe care le aduc, puteți lua măsurile necesare
            de securitate astel încât să puteți naviga cu încredere pe internet.</span></p>
        <hr>
        <p class="c2 c12"><span class="c4"></span></p>
        <p class="c16"><span class="c4">ÎNTREBĂRI, NELĂMURIRI, RECLAMAȚII</span></p>
        <p class="c1"><span class="c4">Dacă aveți întrebări cu privire la informațiile dvs. personale sau dacă doriți
            să vă exercitați drepturile sau aveți nelămuriri sau reclamații, vă rugăm să ne contactați, folosind
            datele de mai jos: </span></p>
        <p class="c1"><span class="c4">Telefon: &nbsp;0743 697 29</span></p>
        <p class="c1"><span>Email: </span><span class="c32"><a class="c7"
              href="mailto:lukinstall24perfect@yahoo.com">lukinstall24perfect@yahoo.com</a></span></p>
        <p class="c1"><span class="c4">Adresa: sat Clinceni Com. Clinceni, Str. Ciocarliei, Lotul 1, Tarlaua 33/1,
            Parcelele Nr. 195, Nr. 195/1/32 Si Nr. 195/1/33 Identificat Cadastral Sub Nr. 60606-C1-U1, Intabulat In
            C.F. Nr. 60606 A Clinceni</span></p>
        <p class="c1 c2"><span class="c4"></span></p>
        <p class="c1 c2"><span class="c4"></span></p>
        <p class="c1 c2"><span class="c4"></span></p>
        <p class="c1 c2"><span class="c4"></span></p>
        <p class="c17 c2"><span class="c4"></span></p>
        <p class="c1 c2"><span class="c4"></span></p>
        <p class="c1 c2"><span class="c4"></span></p>
        <p class="c29 c2"><span class="c4"></span></p>
        <p class="c29 c2"><span class="c4"></span></p>
        <p class="c29 c2"><span class="c4"></span></p>
      </div>
    </div>
  </div>
</div>
