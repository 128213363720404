<!--Banner Section-->
<section class="banner-section">
  <div class="main-slider-carousel owl-carousel owl-theme">

    <div class="slide">
      <div class="image-layer" style="background-image:url(/assets/images/slider/slide-1.jpg)"></div>
      <div class="auto-container">
        <!-- Content Boxed -->
        <div class="content-boxed">
          <div class="inner-boxed">
            <!-- <div class="title">protecting what matters!</div> -->
            <div class="boxed-bg">
              <h1>Ai nevoie de un instalator bun?</h1>
              <div class="text">
                Ne deplasam rapid, oriunde in Bucuresti si Ilfov:
                <ul class="half-list">
                  <li><span></span>Sector 1</li>
                  <li><span></span>Sector 4</li>
                  <li><span></span>Sector 2</li>
                  <li><span></span>Sector 5</li>
                  <li><span></span>Sector 3</li>
                  <li><span></span>Sector 6</li>
    
                </ul>
  
              </div>
            </div>
            
            <ul class="icon__list">
              <li>
                <img src="/assets/images/slider/instalatii-sanitare--icon.png" alt="">
                <p>Instalatii sanitare</p>
              </li>
              <li>
                <img src="/assets/images/slider/instalatii-termice--icon.png" alt="">
                <p>Instalatii termice</p>
              </li>
              <li>
                <img src="/assets/images/slider/desfundari-profesionale--icon.png" alt="">
                <p>Desfunari profesionale</p>
              </li>
            </ul>
            <div class="btns-box clearfix">
              <a routerLink="/contact" class="theme-btn btn-style-one"><span class="txt">
                  Cere oferta</span></a>
              <a routerLink="/contact" class="talk">suna 0743-697-295</a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="slide">
      <div class="image-layer" style="background-image:url(/assets/images/slider/slide-2.jpg)"></div>
      <div class="auto-container--slide2">
        <!-- Content Boxed -->
        <div class="content-boxed alternate clearfix">
          <div class="inner-boxed">
            <div class="boxed-bg">
              <div class="title">INSTALATOR 24/7</div>
              <h1>URGENTELE APAR</h1>
              <div class="text">Noi te putem ajuta!</div>
              <div class="btns-box clearfix">
                <a routerLink="/contact" class="theme-btn btn-style-one"><span class="txt">Suna 24/7</span></a>
               
              </div>
              <img src="/assets/images/slider/slide-2--inner-boxed.png" alt="">
            </div>
          </div>
        </div>
        <img class="second__img" src="/assets/images/slider/slide-2--second.png" alt="">
      </div>

    </div>

  </div>

<!-- <owl-carousel-o [options]="customOptions" class="main-slider-carousel  owl-carousel owl-theme">
    <ng-template carouselSlide>
      <div class="slide">
        <div class="image-layer" style="background-image:url(/assets/images/slider/slide-1.jpg)"></div>
        <div class="auto-container">
        
          <div class="content-boxed">
            <div class="inner-boxed">
            
              <div class="boxed-bg">
                <h1>Ai nevoie de un instalator bun?</h1>
                <div class="text">
                  Ne deplasam rapid, oriunde in Bucuresti si Ilfov:
                  <ul class="half-list">
                    <li><span></span>Sector 1</li>
                    <li><span></span>Sector 4</li>
                    <li><span></span>Sector 2</li>
                    <li><span></span>Sector 4</li>
                    <li><span></span>Sector 3</li>
                    <li><span></span>Sector 6</li>

                  </ul>

                </div>
              </div>

              <ul class="icon__list">
                <li>
                  <img src="/assets/images/slider/instalatii-sanitare--icon.png" alt="">
                  <p>Instalatii sanitare</p>
                </li>
                <li>
                  <img src="/assets/images/slider/instalatii-termice--icon.png" alt="">
                  <p>Instalatii termice</p>
                </li>
                <li>
                  <img src="/assets/images/slider/desfundari-profesionale--icon.png" alt="">
                  <p>Desfunari profesionale</p>
                </li>
              </ul>
              <div class="btns-box clearfix">
                <a routerLink="/contact" class="theme-btn btn-style-one"><span class="txt">
                    Cere oferta</span></a>
                <a routerLink="/contact" class="talk">suna 0743-697-295</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </ng-template>

    <ng-template carouselSlide>
      <div class="slide">
        <div class="image-layer" style="background-image:url(/assets/images/slider/slide-2.jpg)"></div>
        <div class="auto-container--slide2">
          
          <div class="content-boxed alternate clearfix">
            <div class="inner-boxed">
              <div class="boxed-bg">
                <div class="title">INSTALATOR NON-STOP</div>
                <h1>URGENTELE APAR</h1>
                <div class="text">Noi te putem ajuta!</div>
                <div class="btns-box clearfix">
                  <a routerLink="/contact" class="theme-btn btn-style-one"><span class="txt">Suna 24/7</span></a>

                </div>
                <img src="/assets/images/slider/slide-2--inner-boxed.png" alt="">
              </div>
            </div>
          </div>
          <img class="second__img" src="/assets/images/slider/slide-2--second.png" alt="">
        </div>

      </div>
    </ng-template>
  </owl-carousel-o>  -->
</section>
<!--End Banner Section-->
