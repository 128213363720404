<!-- Page Title -->
<section class="page-title" style="background-image: url(/assets/images/saab-trusted-plumber.jpg); background-size: cover;">
  <div class="auto-container">
    <h1>Contact</h1>
    <ul class="page-breadcrumb" style="display: none">
      <li><a href="/">Acasa</a></li>
      <li>Contact</li>
    </ul>
  </div>
</section>
<!-- End Page Title -->


<!-- Fluid Section Two -->
<section class="fluid-section-two contact-page-section">
  <div class="container clearfix">


    <div class="row">
      <!--Form Column-->
      <div class="form-column col-lg-6 col-md-12 col-sm-12">
        <div class="inner-column">
          <!-- Sec Title -->
          <div class="sec-title">
            <div class="icon">
              <img src="/assets/images/icons/cog.svg" alt="" />
            </div>
            <h2>Trimite-ne un mesaj</h2>
            <div class="text">Sau cere o oferta</div>
          </div>
          <div class="content">
            <!-- Contact Form -->
            <div class="contact-form">
              <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)">
                <div class="row clearfix">

                  <div class="form-group col-md-12 col-sm-12 col-xs-12">
                    <div class="alert alert-danger" *ngIf="!contactForm.valid && contactForm.touched">
                      <span class="help-block">Va rugam sa completati toate datele</span>
                    </div>
                  </div>

                  <div class="form-group col-md-6 col-sm-6 col-xs-12">
                    <input type="text" name="name" required minlength="3" ngModel placeholder="Nume">
                  </div>

                  <div class="form-group col-md-6 col-sm-6 col-xs-12">
                    <input type="text" name="phone" placeholder="Telefon" required minlength="3" ngModel>
                  </div>

                  <div class="form-group col-md-6 col-sm-6 col-xs-12">
                    <input type="email" name="email" placeholder="Email" required minlength="6" ngModel>
                  </div>

                  <div class="form-group col-md-6 col-sm-6 col-xs-12">
                    <input type="text" name="city" placeholder="Locuiesc in (Bucuresti, Ilfov)" required minlength="3" ngModel>
                  </div>

                  <div class="form-group col-md-12 col-sm-12 col-xs-12">
                    <textarea name="message" ngModel placeholder="Solicitare pe scurt"></textarea>
                  </div>

                  <div class="form-group col-md-12 col-sm-12 col-xs-12">
                    <input ngModel required type="checkbox" name="gdpr">
                    <label for="gdpr">Sunt de acord cu inregistrarea, stocarea si prelucrarea datelor mele personale conform <a routerLink="/confidentialitate">Politicii de Confidentialitate</a></label>
                  </div>

                  <div class="form-group btn-column col-md-12 col-sm-12 col-xs-12">
                    <button type="submit" [disabled]="!contactForm.valid" class="theme-btn submit-btn"><span class="txt">Trimite <i
                          class="fa fa-angle-right"></i></span></button>
                  </div>

                </div>

              </form>
            </div>

          </div>
        </div>
      </div>
      <div class="content-column col-lg-6 col-md-12 col-sm-12">
        <div class="inner-column">
          <h4>Vrei sa ne pui o intrebare? <br> Ai  un proiect de amenajare?</h4>
          <div class="text">Contacteaza-ne si stabilim cele mai bune solutii pentru tine.</div>
          <ul class="info-list">
            <li><span class="icon flaticon-call-3"></span><a href="tel:+40743697295">0743 697 295</a></li>
            <li><span class="icon flaticon-email-5"></span><a href="mailto:lukinstall24perfect@yahoo.com">lukinstall24perfect@yahoo.com</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- End Fluid Section One -->

<!-- Clients Section -->
<section class="clients-section">
  <div class="auto-container">

    <div class="sponsors-outer">
      <!--Sponsors Carousel-->
      <ul class="sponsors-carousel owl-carousel owl-theme" style="display: flex; align-items: center;">
        <li class="slide-item">
          <figure class="image-box"><a><img src="/assets/images/romstal-vector-logo.svg" alt=""></a></figure>
        </li>
        <li class="slide-item">
          <figure class="image-box"><a><img src="/assets/images/rehau-logo.svg" alt=""></a></figure>
        </li>
       
      </ul>
    </div>

  </div>
</section>
<!-- End Clients Section -->


