import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instalator-sector-six',
  templateUrl: './instalator-sector-six.component.html',
  styleUrls: ['./instalator-sector-six.component.scss']
})
export class InstalatorSectorSixComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
