import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cum-aerisim-caloriferele-din-locuinta',
  templateUrl: './cum-aerisim-caloriferele-din-locuinta.component.html',
  styleUrls: ['./cum-aerisim-caloriferele-din-locuinta.component.scss']
})
export class CumAerisimCalorifereleDinLocuintaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
