<!-- Fluid Section Two -->
<section class="fluid-section-two contact-page-section">
  <div class="container clearfix">


    <div class="row">
      <!--Form Column-->
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="message">
          <h1> Mesajul tau a fost trimis. Iti vom raspunde in cel mai scurt timp.</h1>
        </div>
      </div>
    </div>
  </div>
</section>
