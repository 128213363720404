<app-slider></app-slider>
<app-feature-section></app-feature-section>

<section class="security-section aic">
  <div class="auto-container">
    <div class="row clearfix">

      <!-- Content Column -->
      <div class="content-column col-lg-6 col-md-12 col-sm-12">
        <div class="inner-column">
          <div class="title"><span class="icon"><img src="/assets/images/icons/cog.svg" alt="" /></span> Instalator
            Bucuresti si Ilfov</div>
          <h2>Instalator autorizat instalatii
            Servicii profesionale cu garantie
          </h2>
          <div class="bold-text">Firma noastra va pune la dispozitie instalator autorizat pentru lucrari profesionale la
            instalatiile sanitare si termice.</div>
          <div class="text">Asiguram rapid instalator ce ofera servicii profesionale, rapide si sigure pentru siguranta
            deplina a caminului dumneavoastra.
            Instalatorii pusi la dispozitie de firma noastra sunt calificati si au experienta de peste
            10 ani in domeniul instalatiilor sanitare, termice.
          </div>
          <div class="bold-text">Ai o urgenta, cauti un instalator in Bucuresti?</div>
          <div class="text">Apeleaza cu incredere la serviciile noastre pentru orice tip de lucrari.
            <ul>
              <li>- Oferim GARANTIE pentru interventiile instalatorilor nostri.</li>
              <li>- Asiguram interventii rapide si sigure pentru Bucuresti si judetul Ilfov.</li>
              <li>- Asiguram instalator 24/7 pentru interventii in regim de urgenta.</li>
            </ul>
          </div>
          <div class="bold-text">Suntem gata sa intervenim prompt si rapid acolo unde situatia nu necesita amanare.
          </div>

        </div>
      </div>

      <!-- Images Column -->
      <div class="images-column col-lg-6 col-md-12 col-sm-12">
        <div class="inner-column">
          <div class="image">
            <img src="/assets/images/instalator-bucuresti.jpg" alt="" />
          </div>
          <div class="image">
            <img src="/assets/images/261856-P4-LPCS-611.jpg" alt="" />
          </div>
          <div class="image">
            <img src="/assets/images/instalator-bucuresti-sector-1.jpg" alt="" />
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<section class="premium-section">
  <div class="pattern-layer" style="background-image:url(assets/images/background/pattern-1.png)"></div>
  <div class="auto-container">
    <!-- Sec Title -->
    <div class="sec-title centered">
      <div class="icon">
        <img src="/assets/images/icons/cog.svg" alt="" />
      </div>
      <h2>CAUTI UN INSTALATOR BUCURESTI SAU ILFOV?</h2>
      <div class="text">Lucram NON-STOP, 7 zile din 7, 24 ore din 24 <br> Garanție de la 1-5 ani pentru fiecare lucrare
        în parte, execuție rapida, prețuri reale și corecte. <br> Pentru fiecare lucrare in parte se oferă certificat de
        garanție, factura și chitanța.</div>
    </div>

    <div class="row clearfix">

      <!-- Security Block -->
      <div class="security-block col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a href=""><img src="/assets/images/instalator-termic.jpg" alt="" /></a>
          </div>
          <div class="lower-content">
            <div class="hover-bg-color"></div>
            <div class="upper-box">
              <div class="icon flaticon-cctv"></div>
              <h5><a href="">INSTALATOR TERMIC</a></h5>
            </div>
            <div class="text">Montam calorifere, centrale termice, pompe de caldura,
              realizam traseu incalzire in pardoseala.
            </div>
          </div>
        </div>
      </div>

      <!-- Security Block -->
      <div class="security-block col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a href="service-detail"><img src="/assets/images/montaj-centrale-termice.jpg" alt="" /></a>
          </div>
          <div class="lower-content">
            <div class="hover-bg-color"></div>
            <div class="upper-box">
              <div class="icon flaticon-lcd"></div>
              <h5><a href="service-detail">MONTAJ CENTRALE TERMICE</a></h5>
            </div>
            <div class="text">Montajul centralei termice se face de un instalator autorizat cu o vasta experienta in
              domeniul instalatiilor termice.</div>
          </div>
        </div>
      </div>

      <!-- Security Block -->
      <div class="security-block col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a href="service-detail"><img src="/assets/images/instalator-non-stop.jpg" alt="" /></a>
          </div>
          <div class="lower-content">
            <div class="hover-bg-color"></div>
            <div class="upper-box">
              <div class="icon flaticon-lcd"></div>
              <h5><a href="service-detail">INSTALATOR 24/7</a></h5>
            </div>
            <div class="text">Asiguram un serviciu de urgenta in cazul oricaror tip de avarii aparut la nivelul
              instalatiilor, cu instalatori profesionisti si solutii eficiente.</div>
          </div>
        </div>
      </div>

      <!-- Security Block -->
      <div class="security-block col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a href="service-detail"><img src="/assets/images/instalator-santar.jpg" alt="" /></a>
          </div>
          <div class="lower-content">
            <div class="hover-bg-color"></div>
            <div class="upper-box">
              <div class="icon flaticon-lcd"></div>
              <h5><a href="service-detail">INSTALATOR SANITAR</a></h5>
            </div>
            <div class="text">Asiguram la domiciliu instalatori autorizati ce vor efectua lucrari cu orice grad de
              dificultate, cu garantie post interventie.</div>
          </div>
        </div>
      </div>

      <!-- Security Block -->
      <div class="security-block col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a href="service-detail"><img src="/assets/images/DESFUNDARI-PROFESIONALE.jpg" alt="" /></a>
          </div>
          <div class="lower-content">
            <div class="hover-bg-color"></div>
            <div class="upper-box">
              <div class="icon flaticon-alarm-1"></div>
              <h5><a href="service-detail">DESFUNDARI PROFESIONALE</a></h5>
            </div>
            <div class="text">Lucrări de desfundare canalizare, curăţarea scurgerilor de la blocuri de locuinţe,
              desfundări de ţevi, coloane de apă verticale sau orizontale, depanarea instalaţiilor avariate.</div>
          </div>
        </div>
      </div>

      <!-- Security Block -->
      <div class="security-block col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a href="service-detail"><img src="/assets/images/electricieni-autorizati.jpg" alt="" /></a>
          </div>
          <div class="lower-content">
            <div class="hover-bg-color"></div>
            <div class="upper-box">
              <div class="icon flaticon-keyboard"></div>
              <h5><a href="service-detail">ELECTRICIENI AUTORIZATI</a></h5>
            </div>
            <div class="text">Serviciile electrice efectuate de catre specialistii nostri se adreseaza atat firmelor,
              cat si persoanelor fizice din Bucuresti si judetul Ilfov.</div>
          </div>
        </div>
      </div>



    </div>

  </div>
</section>
<section class="call-to-action-section">
  <div class="auto-container">
    <div class="row clearfix">
      <!-- Title Column -->
      <div class="title-column col-lg-8 col-md-12 col-sm-12">
        <div class="inner-column">
          <div class="phone flaticon-call-3"></div>
          <div class="text"><span>Suna un instalator Bucuresti si Ilfov <br></span> Telefon: <a
              href="tel:+40743697295"><span>0743-697-295</span></a></div>
        </div>
      </div>
      <!-- Button Column -->
      <div class="button-column col-lg-4 col-md-12 col-sm-12">
        <div class="inner-column">
          <a href="/contact" class="theme-btn btn-style-two"><span class="txt">CERE OFERTA</span></a>
        </div>
      </div>
    </div>
  </div>
</section>
<app-old-site-section></app-old-site-section>

<!-- Gallery Section -->
<section class="gallery-section">
  <div class="auto-container">
    <div class="sec-title centered">
      <div class="icon"><img src="/assets/images/icons/cog.svg" alt="">
      </div>
      <h2>Esti curios ce am lucrat pana acum? <br> Iata o parte din realizarile echipei.</h2>
      <a routerLink="/lucrari" class="theme-btn btn-style-one"><span class="txt"> Vezi mai mult</span></a>
    </div>

    <!-- Video Gallery -->
    <div class="video-gallery">
      <iframe src="https://www.youtube.com/embed/wnYnrmGF-t8" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
      <!-- <img src="/assets/images/INSTALATOR-BUCURESTI-INSTALATII-BUCURESTI-INSTALATOR-NON-STOP-32.jpg" alt="" />
				<div class="overlay-box">
					<div class="overlay-inner">
						<a href="" class="lightbox-image video-box"><span class="fa fa-play"><i class="ripple"></i></span></a>
						
					</div>
				</div> -->
    </div>

    <div class="row clearfix">

      <!-- Gallery Block -->
      <div class="gallery-block col-lg-8 col-md-12 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <img src="/assets/images/montaj_profesional_instalatii.jpg" alt="" />
            <!-- Overlay Box -->
            <div class="overlay-box">
              <div class="overlay-inner">
                <div class="content">
                  <h4><a>Montaj profesional instalatii</a></h4>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Gallery Block -->
      <div class="gallery-block col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <img src="/assets/images/montaj-profesional-cada.jpg" alt="" />
            <!-- Overlay Box -->
            <div class="overlay-box">
              <div class="overlay-inner">
                <div class="content">
                  <h4><a>Montaj profesional cada</a></h4>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Gallery Block -->
      <div class="gallery-block col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <img src="/assets/images/montaj-obiecte-sanitare.jpg" alt="" />
            <!-- Overlay Box -->
            <div class="overlay-box">
              <div class="overlay-inner">
                <div class="content">
                  <h4><a>Montaj chiuveta baie</a></h4>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Gallery Block -->
      <div class="gallery-block col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <img src="/assets/images/montaj-boiler.jpg" alt="" />
            <!-- Overlay Box -->
            <div class="overlay-box">
              <div class="overlay-inner">
                <div class="content">
                  <h4><a>Montaj Boiler</a></h4>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Gallery Block -->
      <div class="gallery-block col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <img src="/assets/images/montaj-profesional-chiuveta.jpg" alt="" />
            <!-- Overlay Box -->
            <div class="overlay-box">
              <div class="overlay-inner">
                <div class="content">
                  <h4><a>Montaj obiecte sanitare</a></h4>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
<!-- End Gallery Section -->

<!-- Testimonial Section -->
<section class="testimonial-section" style="background-image: url(/assets/images/luk-install-pareri.jpg)">
  <div class="auto-container">
    <!-- Sec Title -->
    <div class="sec-title light centered">
      <div class="icon">
        <img src="/assets/images/icons/cog.svg" alt="" />
      </div>
      <h2>Ce spun clientii</h2>

    </div>

    <div class="inner-container">

      <owl-carousel-o [options]="customOptions" class="testimonial-carousel">
        <ng-template carouselSlide>
          <!-- Testimonial Block -->
          <div class="testimonial-block">
            <div class="inner-box">

              <div class="text">Tocmai ce-a plecat un instalator de la Luk Install de la mine. Nu pot sa spun cat sunt
                de multumita. I-am trecut numarul de telefon in agenda, sa-l am la indemana, pe viitor.</div>
              <div class="author-box">
                <div class="author-inner">
                  <div class="author-image">
                    <img src="https://via.placeholder.com/49x49" alt="" />
                  </div>
                  <h6>Maria</h6>
                  <div class="designation">Bucuresti</div>
                  <div class="rating">
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </ng-template>
        <ng-template carouselSlide>

          <!-- Testimonial Block -->
          <div class="testimonial-block">
            <div class="inner-box">
              <div class="text">Mi-am facut toata instalatia termica si sanitara pentru noua noastra casa. Mi-a placut
                ca a tinut cont de faptul ca suntem nerabdatori sa ne mutam in casa noua, insa au lucrat cu atentie la
                detalii, fara sa lase un dezastru in urma. Recomand cu incedere. </div>
              <div class="author-box">
                <div class="author-inner">
                  <div class="author-image">
                    <img src="https://via.placeholder.com/49x49" alt="" />
                  </div>
                  <h6>George</h6>
                  <div class="designation">Cartier Cosmopolis</div>
                  <div class="rating">
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </ng-template>

      </owl-carousel-o>

    </div>
  </div>
</section>
<!-- End Testimonial Section -->

<section class="call-to-action-section-two"
  style="background-image: url(/assets/images/instalator-bucuresti-ilfov.jpg)">
  <div class="auto-container">
    <!-- Content Boxed -->
    <div class="content-boxed">
      <div class="inner-boxed">
        <div class="title">CU NOI, AI DOAR DE CASTIGAT!</div>
        <h1>Suntem partener ROMSTAL</h1>
        <div class="text">Beneficiati pana la 25% reducere pentru toate materialele Romstal</div>
        <div class="btns-box clearfix">
          <a routerLink="/contact" class="theme-btn btn-style-one"><span class="txt">
              Cere oferta</span></a>
          <a routerLink="/contact" class="talk">Suna un instalator <span>0743 697 295</span></a>
        </div>
      </div>
    </div>
  </div>
</section>
