	<!-- Page Title -->
	<section class="page-title" style="background-image: url(/assets/images/instalator-non-stop-cover.jpg)">
	  <div class="auto-container">
	    <h1>Instalator 24/7</h1>
	    <ul class="page-breadcrumb" style="display: none">
	      <li><a href="/">Acasa</a></li>
	      <li>Instalator 24/7</li>
	    </ul>
	  </div>
	</section>
	<!-- End Page Title -->

	<!-- About Section -->
	<div class="about-section">
	  <div class="auto-container">
	    <div class="row clearfix">

	      <!-- Content Column -->
	      <div class="content-column col-lg-6 col-md-12 col-sm-12">
	        <div class="inner-column">
	          <div class="title"><span class="icon"><img src="/assets/images/icons/cog.svg" alt="" /></span> Luk
	            Install - Instalator Urgente Bucuresti si Ilfov</div>
	          <h2>Instalator 24/7 - un serviciu cu disponibilitate imediata</h2>
	          <div class="text">
	            <p>Echipa noastra sta la dispozitia dumneavoastra oricand aveti nevoie de un instalator 24/7 in
	              Bucuresti si Ilfov sau de un instalator sanitar si termic.</p>
	            <p>Asiguram un <strong>serviciu de urgenta</strong> in cazul oricaror tip de avarii aparut la nivelul
	              instalatiilor dumnevoastra, cu instalatori ce au o pregatire prefesionala la un nivel inalt si folosesc
	              cele mai noi tehnologii și echipamente pentru solutionarea problemelor. </p>
	            <p>Luk Install va ofera un <strong>instalator 24/7</strong> cu deplasare in regim de urgenta la orice
	              ora din zi/noapte</p>
	          </div>
	          <div class="question">Suna un instalator urgente <a href="tel:+40743697295">0743-697-295</a></div>
	        </div>
	      </div>

	      <!-- Images Column -->
	      <div class="image-column col-lg-6 col-md-12 col-sm-12">
	        <div class="inner-column">
	          <div class="award-image">
	            <img src="assets/images/instalator-non-stop-bucuresti.png" alt="" />
	          </div>
	          <div class="image">
	            <img src="/assets/images/instalator-non-stop-page.jpg" alt="" />
	          </div>
	        </div>
	      </div>
	    </div>
	  </div>
	</div>
	<!-- End About Section -->

	<section class="featured-section-two">
	  <div class="image-layer" style="background-image:url(assets/images/background/pattern-2.png)"></div>
	  <div class="auto-container">
	    <div class="inner-container">

	      <div class="clearfix">

	        <app-feature-section></app-feature-section>

	      </div>

	    </div>
	  </div>
	</section>
	<!-- Counter Section -->
	<section class="counter-section">
	  <div class="auto-container">

	    <!-- Fact Counter -->
	    <div class="fact-counter">
	      <div class="row clearfix">

	        <!-- Column -->
	        <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
	          <div class="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
	            <div class="content">
	              <div class="count-outer count-box">
	                <span class="count-text" data-speed="3000" data-stop="350">0</span>+
	              </div>
	              <h4 class="counter-title">Lucrari Instalatii Termice</h4>
	            </div>
	          </div>
	        </div>

	        <!-- Column -->
	        <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
	          <div class="inner wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
	            <div class="content">
	              <div class="count-outer count-box alternate">
	                <span class="count-text" data-speed="5000" data-stop="460">0</span>+
	              </div>
	              <h4 class="counter-title">Lucrari Instalatii Sanitare</h4>
	            </div>
	          </div>
	        </div>

	        <!-- Column -->
	        <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
	          <div class="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
	            <div class="content">
	              <div class="count-outer count-box">
	                <span class="count-text" data-speed="2000" data-stop="15">0</span>+
	              </div>
	              <h4 class="counter-title">Ani de Experienta</h4>
	            </div>
	          </div>
	        </div>

	        <!-- Column -->
	        <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
	          <div class="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
	            <div class="content">
	              <div class="count-outer count-box">
	                <span class="count-text" data-speed="3500" data-stop="390">0</span>+
	              </div>
	              <h4 class="counter-title">Urgente Rezolvate</h4>
	            </div>
	          </div>
	        </div>

	      </div>
	    </div>

	  </div>
	</section>
	<!-- End Counter Section -->

	<section class="featured-section-three">
	  <div class="auto-container">
	    <div class="inner-container">
	      <h3>Serviciul de <strong>instalator urgente </strong> oferit de LUK INSTALL cuprinde:</h3>
	      <div class="row clearfix">

	        <!-- Left Column -->
	        <div class="left-column col-lg-4 col-md-6 col-sm-12">
	          <div class="inner-column">

	            <!-- Featured Block Three -->
	            <div class="featured-block-three">
	              <div class="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms"
	                style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInLeft;">
	                <div class="icon-box">
	                  <span class="icon"><img src="assets/images/icons/speed.svg" alt=""></span>
	                </div>
	                <h5><a>Inlocuire tevi sparte sau fisurate</a></h5>
	                <div class="text">Inlocuim rapid calorifere cu probleme</div>
	              </div>
	            </div>

	            <!-- Featured Block Three -->
	            <div class="featured-block-three color-two">
	              <div class="inner-box wow fadeInLeft animated" data-wow-delay="150ms" data-wow-duration="1500ms"
	                style="visibility: visible; animation-duration: 1500ms; animation-delay: 150ms; animation-name: fadeInLeft;">
	                <div class="icon-box">
	                  <span class="icon"><img src="assets/images/icons/guranatee.svg" alt=""></span>
	                </div>
	                <h5><a>Desfundari scurgeri, canalizare, vase de toaleta</a></h5>
	                <div class="text">Interventii instalatii sanitare
	                </div>
	              </div>
	            </div>

	          </div>
	        </div>

	        <!-- Icon Column -->
	        <div class="icon-column col-lg-4 col-md-12 col-sm-12">
	          <div class="inner-column">
	            <div class="circle-one"></div>
	            <div class="circle-two"></div>
	            <div class="circle-three"></div>
	            <div class="icon">
	              <img src="assets/images/non-stop-preview.svg" width="70%" alt="">
	            </div>
	          </div>
	        </div>

	        <!-- Right Column -->
	        <div class="right-column col-lg-4 col-md-6 col-sm-12">
	          <div class="inner-column">

	            <!-- Featured Block Three -->
	            <div class="featured-block-three color-two style-two">
	              <div class="inner-box wow fadeInRight animated" data-wow-delay="0ms" data-wow-duration="1500ms"
	                style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInRight;">
	                <div class="icon-box">
	                  <span class="icon"><img src="assets/images/icons/experts.svg" alt=""></span>
	                </div>
	                <h5><a>Montaj urgent centrala termica</a></h5>
	                <div class="text">Verificare centrala termica</div>
	              </div>
	            </div>

	            <!-- Featured Block Three -->
	            <div class="featured-block-three style-two">
	              <div class="inner-box wow fadeInRight animated" data-wow-delay="150ms" data-wow-duration="1500ms"
	                style="visibility: visible; animation-duration: 1500ms; animation-delay: 150ms; animation-name: fadeInRight;">
	                <div class="icon-box">
	                  <span class="icon"><img src="assets/images/icons/save.svg" alt=""></span>
	                </div>
	                <h5><a>Schimbare robineti de trecere, robineti generali etc</a></h5>
	                <div class="text">Montaj in regim de urgenta instalatie sanitara/termica</div>
	              </div>
	            </div>

	          </div>
	        </div>

	      </div>
	    </div>
	  </div>
	</section>

	<!-- Call To Action Section Two -->
	<section class="call-to-action-section-two">
	  <div class="auto-container">
	    <div class="row clearfix">
	      <div class="col-lg-7 col-md-7 col-sm-12">
	        <!-- Content Boxed -->
	        <div class="content-boxed">
	          <div class="inner-boxed">
	            <div class="title">INSTALATOR URGENTE</div>
	            <h1>TIMP DE INTERVENTIE RAPID</h1>
	            <div class="text">Asiguram un timp de raspuns cat mai eficient pentru a solutiona cat mai repede toate problemele aparute la nivelul instalatiilor.</div>
	            <div class="btns-box clearfix">
	              <a href="tel:+40743697295" class="theme-btn btn-style-one"><span class="txt"><i
	                    class="flaticon-shield-1"></i>
						Suna un instalator</span></a>
	              <a href="mailto:office@lukinstall.ro" class="talk">Cere oferta: <span>office@lukinstall.ro</span></a>
	            </div>
	          </div>
	        </div>
	      </div>
	      <div class="col-lg-5 col-md-5 col-sm-12">
	        <img src="/assets/images/instalator-urgent.jpg" alt="">
	      </div>
	    </div>
	  </div>
	</section>
	<!-- End Call To Action Section Two -->



	<section class="premium-section style-two">
		<div class="auto-container">
		  <h2>Instalator Bucuresti si Ilfov</h2>
		  <div class="row clearfix">
	  
			<!-- Security Block -->
			<div class="security-block col-lg-4 col-md-6 col-sm-12">
			  <div class="inner-box">
				<div class="image">
				  <a><img src="assets/images/resources/Montaj-Obiecte-Sanitare.jpg" alt=""></a>
				</div>
				<div class="lower-content">
				  <div class="hover-bg-color"></div>
				  <div class="upper-box">
	  
					<h5><a>Montaj Obiecte Sanitare</a></h5>
				  </div>
				  <div class="text">
					<ul>
					  <li><span>></span> Chiuvete</li>
					  <li><span>></span> Lavuaruri</li>
					  <li><span>></span> Cazi de baie</li>
					  <li><span>></span> Cabine de dus</li>
					  <li><span>></span> Vase de WC</li>
					  <li><span>></span> Mașini de spălat vase</li>
					  <li><span>></span> Masini de spalat rufe</li>
					  <li><span>></span> Mașini de uscat rufe</li>
					  <li><span>></span> Boilere </li>
					  <li><span>></span> Centrale termice</li>
					</ul>
	  
	  
	  
				  </div>
				</div>
			  </div>
			</div>
	  
			<!-- Security Block -->
			<div class="security-block col-lg-4 col-md-6 col-sm-12">
			  <div class="inner-box">
				<div class="image">
				  <a><img src="assets/images/resources/Montaj-Profesional.jpg" alt=""></a>
				</div>
				<div class="lower-content">
				  <div class="hover-bg-color"></div>
				  <div class="upper-box">
	  
					<h5><a>Montaj Profesional</a></h5>
				  </div>
				  <div class="text">
					<ul>
					  <li><span>></span> Baterii cazi</li>
					  <li><span>></span> Chiuvete, bideuri</li>
					  <li><span>></span> Centrale termice</li>
					  <li><span>></span> Calorifere</li>
					  <li><span>></span> Robineti de trecere</li>
					  <li><span>></span> Robineti de calorifere</li>
					  <li><span>></span> Hote</li>
					  <li><span>></span> Cuptoare</li>
					  <li><span>></span> Apometre</li>
					  <li><span>></span> Filtre de apa</li>
					</ul>
				  </div>
				</div>
			  </div>
			</div>
	  
			<!-- Security Block -->
			<div class="security-block col-lg-4 col-md-6 col-sm-12">
			  <div class="inner-box">
				<div class="image">
				  <a><img src="assets/images/resources/Servicii-Complete.jpg" alt=""></a>
				</div>
				<div class="lower-content">
				  <div class="hover-bg-color"></div>
				  <div class="upper-box">
					<h5><a>Servicii Complete</a></h5>
				  </div>
				  <div class="text">
					<ul>
					  <li><span>></span> Desfundare profesionala</li>
					  <li><span>></span> Desfundare canalizare</li>
					  <li><span>></span> Instalații electrice (Autorizat ANR)</li>
					  <li><span>></span> Desfundari coloane comune</li>
					  <li><span>></span> Înlocuire coloane orizontale subsoluri</li>
					  <li><span>></span> Desfundari coloane comune</li>
					  <li><span>></span> Desfundare canalizare</li>
					  <li><span>></span> Inlocuire tevi sparte</li>
					  <li><span>></span> Inlocuire tablouri electrice</li>
					</ul>
				  </div>
				</div>
			  </div>
			</div>
	  
		  </div>
	  
		</div>
	  </section>
	  <section class="call-to-action-section">
		<div class="auto-container">
			<div class="row clearfix">
				<!-- Title Column -->
				<div class="title-column col-lg-8 col-md-12 col-sm-12">
					<div class="inner-column">
						<div class="phone flaticon-call-3"></div>
						<div class="text"><span>Suna un instalator Bucuresti si Ilfov pentru servicii rapide si de durata. <br></span> Telefon: <span>0743-697-295</span></div>
					</div>
				</div>
				<!-- Button Column -->
				<div class="button-column col-lg-4 col-md-12 col-sm-12">
					<div class="inner-column">
						<a href="/contact" class="theme-btn btn-style-two"><span class="txt"> CERE OFERTA</span></a>
					</div>
				</div>
			</div>
		</div>
	  </section>
