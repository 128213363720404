import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chiuveta-potrivita-pentru-baia-ta',
  templateUrl: './chiuveta-potrivita-pentru-baia-ta.component.html',
  styleUrls: ['./chiuveta-potrivita-pentru-baia-ta.component.scss']
})
export class ChiuvetaPotrivitaPentruBaiaTaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
