	<!-- Page Title -->
	<section class="page-title" style="background-image: url(/assets/images/instalator-sanitar.jpg)">
	  <div class="auto-container">
	    <h1>Instalator Sanitar</h1>
	    <ul class="page-breadcrumb" style="display: none">
	      <li><a href="/">Acasa</a></li>
	      <li>Instalator Sanitar</li>
	      
	    </ul>
	  </div>
	</section>
	<!-- End Page Title -->

	<!-- Sidebar Page Container -->
	<div class="sidebar-page-container left-sidebar">
	  <div class="auto-container">
	    <div class="row clearfix">
	      <!-- Sidebar Side -->
	      <div class="sidebar-side col-lg-4 col-md-12 col-sm-12">
	        <app-sidebar></app-sidebar>
	      </div>

	      <!-- Content Side -->
	      <div class="content-side col-lg-8 col-md-12 col-sm-12">
	        <div class="service-detail">
	          <div class="inner-box">
	            <div class="images-box">
	              <div class="row clearfix">
	                <!-- Image Column -->
	                <div class="image-column col-lg-6 col-md-6 col-sm-12">
	                  <div class="image">
	                    <img src="/assets/images/instalator-sanitar-1.jpg" alt="" />
	                  </div>
	                </div>
	                <!-- Image Column -->
	                <div class="image-column col-lg-6 col-md-6 col-sm-12">
	                  <div class="image">
	                    <img src="/assets/images/instalator-sanitar-2.jpg" alt="" />
	                  </div>
	                </div>
	              </div>
	            </div>
	            <div class="what-we-can-do">
	              <div class="row clearfix">
	                <div class="col-lg-6 col-md-6 col-sm-12">
	                  <p>CE PUTEM FACE?</p>
	                </div>
	                <div class="col-lg-6 col-md-6 col-sm-12">
	                  <ul>
	                    <li>Reparatii instalatii sanitare</li>
	                    <li>Montaj centrale termice</li>
	                    <li>Reparare tevi</li>
	                    <li>Montaj si reparare obiecte sanitare</li>
	                    <li>Desfundare scurgere</li>
	                  </ul>
	                </div>
	              </div>
	            </div>
	            <div class="what-we-can-do-after">
	              <ul>
	                <li>Intervenție în maxim 30 minute</li>
	                <li>Instalator sanitar Bucuresti in toate sectoarele si pe toata raza Ilfov</li>
	                <li>Servicii de URGENTA, 24/7</li>
	              </ul>
	            </div>
	            <div class="lower-content">
	              <h2>Reparatii instalatii sanitare</h2>
	              <p>Asiguram la domiciliul dumneavoastra instalatori autorizati ce vor efectua lucrari cu orice grad de
	                dificultate. Va siguram de un serviciu profesional ce va fi certificat prin expertiza tehnica si va
	                beneficia ulterior de <strong>Garantie</strong>.</p>

	              <div class="two-column">
	                <div class="row clearfix">
	                  <div class="column col-lg-6 col-md-6 col-sm-12">
	                    <ul class="list-style-two">
	                      <li>Reparare si inlocuire tevi</li>
	                      <li>Montaj si reparare obiecte sanitare</li>
	                      <li>Desfundare scurgere si WC</li>
	                    </ul>
	                    <p>Echipa de <strong>instalatori LukInstall Bucuresti</strong> va poate ajuta atat atunci cand a
	                      aparut o defectiune la instalatiile sanitare sau cand doriti sa realizati o noua instalatie
	                      pentru caminul dumneavoastra.</p>
	                    <p>Vom exemplifica gama variata de servicii oferite de firma noastra pentru instalatiile sanitare.
	                      Fiecare interventie asupra instalatiilor dumneavoastra va fi insotita de garantie si veti primi
	                      cele mai bune solutii pentru remedierea avariilor.</p>
	                  </div>
	                  <div class="column col-lg-6 col-md-6 col-sm-12">
	                    <img src="/assets/images/instalator-sanitar-bucuresti.jpeg" alt="" />
	                  </div>
	                </div>
	              </div>

	              <div class="three-columns">
	                <div class="row clearfix">
	                  <div class="col-lg-4 col-md-6 col-sm-12">
	                    <div class="photo_box">
	                      <h4>Reparare inlocuire tevi instalatii</h4>
	                      <div class="image_frame">
	                        <div class="image_wrapper">
	                          <div class="mask"></div>
	                          <img class="scale-with-grid" src="/assets/images/tevi-sanitare.jpg" alt="instalatii sanitare"
	                            width="400" height="266">
	                        </div>
	                      </div>
	                      <div class="hover_color_wrapper">
	                        <p><strong>Repararea tevilor</strong></p>
	                        <p>Inlocuirea segmentelor de teava sparte</p>
	                        <p> Refacerea sau inlocuirea legaturilor, lipiturilor defectuoase pentru tevi indiferent de
	                          materialul folosit cupru, polipropilena sau pexal</p>
	                        <p>Identificarea si repararea cauzelor inundatiilor aparute in baie sau bucatarie</p>
	                        <p>Schimbarea sau repararea robinetilor de siguranta a instalatiei sanitare din baie sau
	                          bucatarie</p>
	                        <p>Desfundarea tevilor de scurgere fie ca problema a aparut la chiuveta sau la cada</p>
	                      </div>
	                    </div>
	                  </div>

	                  <div class="col-lg-4 col-md-6 col-sm-12">
	                    <div class="photo_box">
	                      <h4>Reparare inlocuire obiecte sanitare</h4>
	                      <div class="image_frame">
	                        <div class="image_wrapper">
	                          <div class="mask"></div>
	                          <img class="scale-with-grid" src="/assets/images/obiecte-sanitare.jpg"
	                            alt="instalatii sanitare" width="400" height="266">
	                        </div>
	                      </div>
	                      <div class="hover_color_wrapper">
	                        <p><strong>Repararea obiectelor sanitare</strong></p>
	                        <p>Reparatia si inlocuirea racordurilor de la baterie</p>
	                        <p>Repararea sau inlocuirea sifonului de scurgere pentru chiuveta, cada sau cabina de dus,
	                          acolo unde situatia o impune</p>
	                        <p>Repararea sau inlocuirea racordurilor de evacuare pentru WC-uri</p>
	                        <p>Repararea instalatiilor de alimentare si scurgere a cabinelor de dus, chiuvete vase de
	                          toaleta.</p>
	                        <p>Inlocuire, montare chiuveta, cada, cabina de dus</p>
	                      </div>
	                    </div>
	                  </div>

	                  <div class="col-lg-4 col-md-6 col-sm-12">
	                    <div class="photo_box">
	                      <h4>Desfundarea sistemelor de scurgere</h4>
	                      <div class="image_frame">
	                        <div class="image_wrapper">
	                          <div class="mask"></div>
	                          <img class="scale-with-grid" src="/assets/images/desfundare_canalizare.jpg"
	                            alt="instalatii sanitare" width="400" height="266">
	                        </div>
	                      </div>
	                      <div class="hover_color_wrapper">
	                        <p><strong>Desfundare scurgere</strong></p>

	                        <p>Desfundarea eficienta pentru sistemul de scurgere de la chiuveta de bucatarie</p>
	                        <p>Desfundare scurgere cada de baie, cabine de dus sau alte elemente din baia dumneavoastra.
	                        </p>
	                        <p>Desfundarea scurgerii, vaselor de WC</p>
	                        <p>Fiecare operatiune ce are la baza serviciul de desfundare este asigurat cu echipamente
	                          profesionale de ultima generatie astfel incat disconfortul creat sa fie cat mai mic.</p>
	                        <p>Desfundare tevi si canalizare pentru baie si bucatarie.</p>
	                      </div>
	                    </div>
	                  </div>
	                </div>
	              </div>

	              <div class="btns-box clearfix">
	                <a href="/contact" class="theme-btn btn-style-one"><span class="txt"> Cere oferta</span></a>
	                <a href="tel:+40743697295" class="talk">Suna acum un instalator <span>0743-697-295</span></a>
	              </div>

	            </div>

	          </div>
	        </div>
	      </div>

	    </div>
	  </div>
	</div>
	<!-- End Sidebar Page Container -->
