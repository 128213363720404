<!-- Page Title -->
<section class="page-title" style="background-image: url(/assets/images/instalator-bucuresti-cover.jpg)">
  <div class="auto-container">
    <h1>Instalator Bucuresti</h1>
    <ul class="page-breadcrumb" style="display: none">
      <li><a href="/">Acasa</a></li>
      <li>Instalator Bucuresti</li>
    </ul>
  </div>
</section>
<!-- End Page Title -->

<!-- About Section -->
<div class="about-section">
  <div class="auto-container">
    <div class="row clearfix">

      <!-- Content Column -->
      <div class="content-column col-lg-6 col-md-12 col-sm-12">
        <div class="inner-column">
          <div class="title"><span class="icon"><img src="/assets/images/icons/cog.svg" alt="" /></span> LUK
            INSTALL - Instalator Bucuresti si Ilfov
          </div>
          <h2>Garantam orice lucrare
            prin Certificat de Garantie
          </h2>
          <div class="text">
            <p>Oferim servicii complete in domeniul instalatiilor. Beneficiezi de siguranta pentru toate tipurile de
              instalatii, prin instalatori autorizati cu experienta si care stiu sa solutioneze eficient fiecare
              lucrare.</p>
            <p><strong>Un instalator Bucuresti se poate deplasa la domiciliul tau, in toate sectoarele din capitala,
                indiferent de ora la care ai nevoie de noi.</strong></p>
            <p>Garantam seriozitatea personalului nostru astfel incat sa fiti multumiti de colaborarea cu noi.</p>

            <h4>Garantia Luk Install: </h4>
            <ul>
              <li>– interventii in regim de urgenta la orice ora;</li>
              <li>– instalatori cu bogata experienta tehnica;</li>
              <li>– preturi corecte pentru clienti;</li>
              <li>– lucram doar cu materiale de cea mai buna calitate.</li>
            </ul>
          </div>
          <div class="question">Ai o intrebare? Suna un instalator: <a href="tel:0743697295">0743-697-295</a></div>

        </div>
      </div>

      <!-- Images Column -->
      <div class="image-column col-lg-6 col-md-12 col-sm-12">
        <div class="inner-column">
          <div class="award-image">
            <img src="assets/images/garantie-instalator.png" alt="" />
          </div>
          <div class="image">
            <img src="assets/images/INSTALATOR-BUCURESTI-1.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="featured-section-two">
  <div class="image-layer" style="background-image:url(assets/images/background/pattern-2.png)"></div>
  <div class="auto-container">
    <div class="inner-container">

      <div class="clearfix">

        <app-feature-section></app-feature-section>

      </div>

    </div>
  </div>
</section>
<!-- Counter Section -->
<section class="counter-section">
  <div class="auto-container">

    <!-- Fact Counter -->
    <div class="fact-counter">
      <div class="row clearfix">

        <!-- Column -->
        <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
          <div class="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
            <div class="content">
              <div class="count-outer count-box">
                <span class="count-text" data-speed="3000" data-stop="350">0</span>+
              </div>
              <h4 class="counter-title">Lucrari Instalatii Termice</h4>
            </div>
          </div>
        </div>

        <!-- Column -->
        <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
          <div class="inner wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
            <div class="content">
              <div class="count-outer count-box alternate">
                <span class="count-text" data-speed="5000" data-stop="460">0</span>+
              </div>
              <h4 class="counter-title">Lucrari Instalatii Sanitare</h4>
            </div>
          </div>
        </div>

        <!-- Column -->
        <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
          <div class="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
            <div class="content">
              <div class="count-outer count-box">
                <span class="count-text" data-speed="2000" data-stop="15">0</span>+
              </div>
              <h4 class="counter-title">Ani de Experienta</h4>
            </div>
          </div>
        </div>

        <!-- Column -->
        <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
          <div class="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
            <div class="content">
              <div class="count-outer count-box">
                <span class="count-text" data-speed="3500" data-stop="390">0</span>+
              </div>
              <h4 class="counter-title">Urgente Rezolvate</h4>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</section>
<!-- End Counter Section -->
<section class="featured-section-three">
  <div class="auto-container">
    <div class="inner-container">
      <h3>De ce sa alegi un instalator <strong> Luk Install?</strong></h3>
      <div class="row clearfix">

        <!-- Left Column -->
        <div class="left-column col-lg-4 col-md-6 col-sm-12">
          <div class="inner-column">

            <!-- Featured Block Three -->
            <div class="featured-block-three">
              <div class="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms"
                style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInLeft;">
                <div class="icon-box">
                  <span class="icon"><img src="assets/images/icons/speed.svg" alt=""></span>
                </div>
                <h5><a>Instalator Urgente</a></h5>
                <div class="text">Intervenim rapid cu instalatori autorizati oricand aveti o urgenta la instalatiile
                  sanitare si termice pentru <strong>zona Bucuresti si Ilfov</strong>.</div>
              </div>
            </div>

            <!-- Featured Block Three -->
            <div class="featured-block-three color-two">
              <div class="inner-box wow fadeInLeft animated" data-wow-delay="150ms" data-wow-duration="1500ms"
                style="visibility: visible; animation-duration: 1500ms; animation-delay: 150ms; animation-name: fadeInLeft;">
                <div class="icon-box">
                  <span class="icon"><img src="assets/images/icons/guranatee.svg" alt=""></span>
                </div>
                <h5><a>Oferim Garantie</a></h5>
                <div class="text">ndiferent de gradul de complexitate al lucrarilor, instalatorii nostrii sunt gata sa
                  intervina prompt, rapid si sigur. <strong>Oferim garantie pentru orice interventie</strong> efectuata.
                </div>
              </div>
            </div>

          </div>
        </div>

        <!-- Icon Column -->
        <div class="icon-column col-lg-4 col-md-12 col-sm-12">
          <div class="inner-column">
            <div class="circle-one"></div>
            <div class="circle-two"></div>
            <div class="circle-three"></div>
            <div class="icon">
              <img src="assets/images/icons/feature-icon.png" alt="">
            </div>
          </div>
        </div>

        <!-- Right Column -->
        <div class="right-column col-lg-4 col-md-6 col-sm-12">
          <div class="inner-column">

            <!-- Featured Block Three -->
            <div class="featured-block-three color-two style-two">
              <div class="inner-box wow fadeInRight animated" data-wow-delay="0ms" data-wow-duration="1500ms"
                style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInRight;">
                <div class="icon-box">
                  <span class="icon"><img src="assets/images/icons/experts.svg" alt=""></span>
                </div>
                <h5><a>Experti in Instalatii</a></h5>
                <div class="text">Va oferim sfaturi eficiente atunci cand vine vorba de instalatiile termice, inclusiv
                  <strong>solutii avansate de proiectare si configurare trasee instalatii.</strong></div>
              </div>
            </div>

            <!-- Featured Block Three -->
            <div class="featured-block-three style-two">
              <div class="inner-box wow fadeInRight animated" data-wow-delay="150ms" data-wow-duration="1500ms"
                style="visibility: visible; animation-duration: 1500ms; animation-delay: 150ms; animation-name: fadeInRight;">
                <div class="icon-box">
                  <span class="icon"><img src="assets/images/icons/save.svg" alt=""></span>
                </div>
                <h5><a>Preturi Corecte</a></h5>
                <div class="text">Este important sa alegeti un instalator de incredere, care sa ofere preturi corecte in
                  raport cu lucrarea pe care o realizeaza.</div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</section>
<section class="call-to-action-section-two"
  style="background-image: url(/assets/images/instalator-bucuresti-ilfov.jpg)">
  <div class="auto-container">
    <!-- Content Boxed -->
    <div class="content-boxed">
      <div class="inner-boxed">
        <div class="title">CU NOI, AI DOAR DE CASTIGAT!</div>
        <h1>Suntem partener ROMSTAL</h1>
        <div class="text">Beneficiati pana la 25% reducere pentru toate materialele Romstal</div>
        <div class="btns-box clearfix">
          <a routerLink="/contact" class="theme-btn btn-style-one"><span class="txt">
              Cere oferta</span></a>
          <a routerLink="/contact" class="talk">Suna un instalator <span>0743 697 295</span></a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="premium-section style-two">
  <div class="auto-container">
    <h2>Instalator Bucuresti si Ilfov</h2>
    <div class="row clearfix">

      <!-- Security Block -->
      <div class="security-block col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a><img src="assets/images/resources/Montaj-Obiecte-Sanitare.jpg" alt=""></a>
          </div>
          <div class="lower-content">
            <div class="hover-bg-color"></div>
            <div class="upper-box">

              <h5><a>Montaj Obiecte Sanitare</a></h5>
            </div>
            <div class="text">
              <ul>
                <li><span>></span> Chiuvete</li>
                <li><span>></span> Lavuaruri</li>
                <li><span>></span> Cazi de baie</li>
                <li><span>></span> Cabine de dus</li>
                <li><span>></span> Vase de WC</li>
                <li><span>></span> Mașini de spălat vase</li>
                <li><span>></span> Masini de spalat rufe</li>
                <li><span>></span> Mașini de uscat rufe</li>
                <li><span>></span> Boilere </li>
                <li><span>></span> Centrale termice</li>
              </ul>



            </div>
          </div>
        </div>
      </div>

      <!-- Security Block -->
      <div class="security-block col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a><img src="assets/images/resources/Montaj-Profesional.jpg" alt=""></a>
          </div>
          <div class="lower-content">
            <div class="hover-bg-color"></div>
            <div class="upper-box">

              <h5><a>Montaj Profesional</a></h5>
            </div>
            <div class="text">
              <ul>
                <li><span>></span> Baterii cazi</li>
                <li><span>></span> Chiuvete, bideuri</li>
                <li><span>></span> Centrale termice</li>
                <li><span>></span> Calorifere</li>
                <li><span>></span> Robineti de trecere</li>
                <li><span>></span> Robineti de calorifere</li>
                <li><span>></span> Hote</li>
                <li><span>></span> Cuptoare</li>
                <li><span>></span> Apometre</li>
                <li><span>></span> Filtre de apa</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Security Block -->
      <div class="security-block col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a><img src="assets/images/resources/Servicii-Complete.jpg" alt=""></a>
          </div>
          <div class="lower-content">
            <div class="hover-bg-color"></div>
            <div class="upper-box">
              <h5><a>Servicii Complete</a></h5>
            </div>
            <div class="text">
              <ul>
                <li><span>></span> Desfundare profesionala</li>
                <li><span>></span> Desfundare canalizare</li>
                <li><span>></span> Instalații electrice (Autorizat ANR)</li>
                <li><span>></span> Desfundari coloane comune</li>
                <li><span>></span> Înlocuire coloane orizontale subsoluri</li>
                <li><span>></span> Desfundari coloane comune</li>
                <li><span>></span> Desfundare canalizare</li>
                <li><span>></span> Inlocuire tevi sparte</li>
                <li><span>></span> Inlocuire tablouri electrice</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</section>
<section class="call-to-action-section">
  <div class="auto-container">
      <div class="row clearfix">
          <!-- Title Column -->
          <div class="title-column col-lg-8 col-md-12 col-sm-12">
              <div class="inner-column">
                  <div class="phone flaticon-call-3"></div>
                  <div class="text"><span>Suna un instalator Bucuresti si Ilfov pentru servicii rapide si de durata. <br></span> Telefon: <span>0743-697-295</span></div>
              </div>
          </div>
          <!-- Button Column -->
          <div class="button-column col-lg-4 col-md-12 col-sm-12">
              <div class="inner-column">
                  <a href="/contact" class="theme-btn btn-style-two"><span class="txt"> CERE OFERTA</span></a>
              </div>
          </div>
      </div>
  </div>
</section>